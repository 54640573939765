import React, { useEffect, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, set } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import Button from '../../../components/Button/Button';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import Input from '../../../components/UI/Input/Input';

import {
  createStaffAccount,
  getNicknames,
  getSpecialities,
} from '../../../store/auth/thunk';
import { AppDispatch } from '../../../store';

import addServerErrors from '../../../helpers/server';
import { translateData } from '../constant';
import { emailValidationString } from '../../../helpers/constant';

import { RegisterInputs } from '../types';

const VERIFY_CODE = 'Epufgk7AuI9GnbFroVAWluxEEC0qeHQ5';

function StaffRegister(): JSX.Element {
  const navigate = useNavigate();
  const { nicknames, specialities } = useSelector((state: any) => state.auth);

  const dispatch: AppDispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<RegisterInputs>();

  const onSubmit: SubmitHandler<RegisterInputs> = (data: RegisterInputs) => {
    dispatch(
      createStaffAccount({
        ...data,
        specialityId: +(data.specialityId as any) as any,
      })
    )
      .then(() => {
        navigate('/tasks');
      })
      .catch((e: any) => {
        const errs = e?.response?.data?.errors;
        if (!errs) return;
        addServerErrors(errs, setError);
      });
  };

  useEffect(() => {
    dispatch(getNicknames());
    dispatch(getSpecialities());
  }, []);

  const nicFirstaneOptions = useMemo(() => {
    if (!nicknames?.nicFirstname) return [];
    return Object.entries(nicknames?.nicFirstname).map(([key, value]: any) => ({
      value: value,
      label: value,
      nicId: key,
    }));
  }, [nicknames?.nicFirstname]);

  const nicLastnameOptions = useMemo(() => {
    if (!nicknames?.nicLastname) return [];
    return Object.entries(nicknames?.nicLastname).map(([key, value]: any) => ({
      value: value,
      label: value,
      nicId: key,
    }));
  }, [nicknames?.nicLastname]);

  return (
    <>
      <div className="section__auth-form">
        <h1 className="form__title">{translateData.UA.registerText}</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="firstName"
            >
              {translateData.UA.labelFirstName}
            </label>
            <input
              className="form__input form__input--login"
              id="firstname"
              style={{ borderColor: errors.firstName?.message && '#FF0000' }}
              {...register('firstName', {
                required: translateData.UA.firstNameErrorMsg,
              })}
              onChange={(e: any) => {
                setValue('firstName', e.target.value.trimStart());
              }}
            />
            {errors.firstName && (
              <span className="form__error">{errors.firstName.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="lastName"
            >
              {translateData.UA.labelLastName}
            </label>
            <input
              className="form__input form__input--login"
              id="lastName"
              style={{ borderColor: errors.lastName?.message && '#FF0000' }}
              {...register('lastName', {
                required: translateData.UA.lastNameErrorMsg,
              })}
              onChange={(e: any) => {
                setValue('lastName', e.target.value.trimStart());
              }}
            />
            {errors.lastName && (
              <span className="form__error">{errors.lastName.message}</span>
            )}
          </fieldset>
          <fieldset className="form__fieldset">
            <label className="form__label form__label--login form__label--custom">
              Нік
            </label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '15px',
                marginBottom: '16px',
              }}
            >
              <NickNameSelect
                options={nicFirstaneOptions}
                name="nicFirstname"
                onchange={(e: any) => {
                  setValue('nicFirstname', +e.nicId as any);
                }}
                errors={errors}
                register={register('nicFirstname', {
                  required: "Заповніть обов'язкове поле",
                })}
              />

              <NickNameSelect
                options={nicLastnameOptions}
                name="nicLastname"
                onchange={(e: any) => {
                  console.log(e);
                  setValue('nicLastname', +e.nicId as any);
                }}
                errors={errors}
                register={
                  register('nicLastname', {
                    required: "Заповніть обов'язкове поле",
                  }) as any
                }
              />
            </div>
          </fieldset>
          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="telephone"
            >
              {translateData.UA.labelPhone}
            </label>
            <InputMask
              id="telephone"
              className="input__field form__input--login"
              placeholder="+38 (XXX) XXX XX XX"
              {...register('telephone', {
                required: translateData.UA.phoneErrorMsg,
                maxLength: {
                  value: 13,
                  message: translateData.UA.phoneErrorMsg,
                },
                minLength: {
                  value: 13,
                  message: 'Необхідно ввести повний номер телефону',
                },
              })}
              mask="+380 (99) 999-9999"
              style={{ borderColor: errors.telephone?.message && 'red' }}
              inputRef={register('telephone').ref}
              onChange={(e: any) => {
                setValue('telephone', e.target.value);
              }}
              onBlur={() => {
                setValue(
                  'telephone',
                  getValues('telephone').replace(/[^+\d]/g, '')
                );
              }}
            />
            {errors.telephone && (
              <span className="form__error">{errors.telephone.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="form__input form__input--login"
              id="email"
              placeholder={translateData.UA.placeholderEmail}
              style={{ borderColor: errors.email?.message && '#FF0000' }}
              {...register('email', {
                required: translateData.UA.emailErrorMsg,
                pattern: emailValidationString,
                minLength: {
                  value: 4,
                  message: translateData.UA.emailErrorMsg,
                },
              })}
            />
            {errors.email?.type === 'pattern' && (
              <span className="form__error">Невірний формат e-mail</span>
            )}
            {errors.email && (
              <span className="form__error">{errors.email.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label className="form__label form__label--login form__label--custom">
              Спеціальність
            </label>

            {specialities.map((item: any) => (
              <div key={item.id}>
                <label>
                  <input
                    className="form__radio"
                    type="radio"
                    value={+item.id}
                    {...register('specialityId', {
                      required: 'Оберіть спеціальність',
                    })}
                  />
                  {item.name}
                </label>
              </div>
            ))}
            {errors.specialityId && (
              <span className="form__error">{errors.specialityId.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label
              className="form__label form__label--login form__label--custom"
              htmlFor="password"
            >
              {translateData.UA.labelPassword}
            </label>
            <input
              className="form__input form__input--login"
              id="password"
              type="password"
              placeholder="Мінімум 8 символів"
              style={{ borderColor: errors.password?.message && '#FF0000' }}
              {...register('password', {
                required: translateData.UA.passwordErrorMsg,
              })}
            />
            {errors.password && (
              <span className="form__error">{errors.password.message}</span>
            )}
          </fieldset>

          <fieldset className="form__fieldset">
            <label>Код реєстрації</label>
            <Input
              type="text"
              value={VERIFY_CODE}
              {...register('code', {
                required: "Заповніть обов'язкове поле",
              })}
            />
          </fieldset>

          <Button view="default" type="submit">
            Зареєструватися
          </Button>
        </form>
      </div>

      <div className="section__extra-info">
        {translateData.UA.hasAccountText}
        <NavLink to="/" style={{ marginLeft: '8px' }}>
          {translateData.UA.signInBtnText}
        </NavLink>
      </div>
    </>
  );
}

export default StaffRegister;

const NickNameSelect = ({
  options,
  name,
  onchange,
  register,
  errors = {},
  placeholder = '',
  errorText = "Заповніть обов'язкове поле",
}: any): JSX.Element => (
  <div
    style={{
      flex: '1 1 50%',
    }}
  >
    <CustomSelect
      placeholder={placeholder}
      onchange={onchange}
      options={options}
      register={register}
      styles={
        errors[name] && {
          control: (styles: any) => ({
            ...styles,
            border: '1px solid #FF0000!important',
            borderRadius: '0',
          }),
        }
      }
    />
    {errors[name] && <div className="form__error">{errorText}</div>}
  </div>
);
