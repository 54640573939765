import { AxiosResponse } from 'axios';
import { projectInstance } from '../helpers/axios';
import { TCreateProjectInputs } from '../forms/CreateProject/types';
import { TUserData } from '../layout/ProjectList/types';

const getList = (type = 3): Promise<any> =>
  projectInstance
    .get(`${type === 3 ? 'project' : 'stage'}/list`)
    .then((response: AxiosResponse) => response.data);

const createProject = (data: TCreateProjectInputs): Promise<any> =>
  projectInstance
    .post('project/create', data)
    .then((response: AxiosResponse) => response.data);

const updateNameProject = (
  data: { name: string },
  slug: string
): Promise<any> =>
  projectInstance
    .post(`project/${slug}/save`, data)
    .then((response: AxiosResponse) => response.data);

const updateProject = (data: { data: any }, slug: string): Promise<any> =>
  projectInstance
    .post(`project/${slug}/save`, data)
    .then((response: AxiosResponse) => response.data);

const getProjectData = (slug: string): Promise<any> =>
  projectInstance
    .get(`project/${slug}/show`)
    .then((response: AxiosResponse) => response.data.projectInfo);

const getStaffTasks = (): Promise<any> =>
  projectInstance
    .get('stage/list')
    .then((response: AxiosResponse) => response.data);

const preloadProjectData = (slug: string, stageId: number): Promise<any> =>
  projectInstance
    .get(`project/${slug}/${stageId}/preload`)
    .then((response: AxiosResponse) => response.data);

const deleteProject = (slug: string): Promise<any> =>
  projectInstance
    .get(`project/${slug}/delete`)
    .then((response: AxiosResponse) => response.data);

const getUserTasksList = (): Promise<any> =>
  projectInstance
    .get('stage/list')
    .then((response: AxiosResponse) => response.data);

const getUsersList = (slug: string): Promise<TUserData[]> =>
  projectInstance
    .get(`project/${slug}/user/list`)
    .then((response: AxiosResponse) => response.data.userList);

const addUserToProject = (
  data: { email: string },
  slug: number
): Promise<any> =>
  projectInstance
    .post(`project/${slug}/user/add`, data)
    .then((response: AxiosResponse) => response.data);

const changeUserRole = (
  data: { role: string; userId: number; email: string },
  slug: string
): Promise<any> =>
  projectInstance
    .post(`project/${slug}/user/save`, data)
    .then((response: AxiosResponse) => response.data);

const deleteUserFromProject = (
  data: { userId: number; email: string },
  slug: string
): Promise<any> =>
  projectInstance
    .post(`project/${slug}/user/delete`, data)
    .then((response: AxiosResponse) => response.data);

const getNotificationList = (): Promise<any> =>
  projectInstance
    .get('informer/list')
    .then((response: AxiosResponse) => response.data);

const setNotificationViewed = (id: number): Promise<any> =>
  projectInstance
    .get(`informer/${id}/save`)
    .then((response: AxiosResponse) => response.data);

const setNotificationsViewed = (): Promise<any> =>
  projectInstance
    .get('informer/clear')
    .then((response: AxiosResponse) => response.data);

const getSingleTask = (slug: string): Promise<any> =>
  projectInstance
    .get(`task/${slug}/single`)
    .then((response: AxiosResponse) => response.data);

const getProjectArchive = (slug: string): Promise<any> =>
  projectInstance
    .get(`project/${slug}/archive`)
    .then((response: AxiosResponse) => response.data);

const getStaffTasksArchive = (): Promise<any> =>
  projectInstance
    .get('task/archive')
    .then((response: AxiosResponse) => response.data);

const sortProjects = (projectsObject: any): any => {
  projectInstance.post('project/sort', { projects: projectsObject });
};

const getCoSum = (slug: string): Promise<any> =>
  projectInstance
    .get(`project/${slug}/comm/prices`)
    .then((response: AxiosResponse) => response.data);

const projectService = {
  updateProject,
  getList,
  createProject,
  updateNameProject,
  getProjectData,
  getUserTasksList,
  getUsersList,
  addUserToProject,
  changeUserRole,
  deleteUserFromProject,
  deleteProject,
  getNotificationList,
  preloadProjectData,
  setNotificationViewed,
  setNotificationsViewed,
  getProjectArchive,
  sortProjects,
  getStaffTasksArchive,
  getCoSum,
  getStaffTasks,
  getSingleTask,
};

export default projectService;
