import { createSlice } from '@reduxjs/toolkit';
import {
  createTask,
  getTaskData,
  getMessagesList,
  getFilesList,
  getArchive,
  getTaskDescription,
  getTaskHistory,
  changePriorityCO,
  updateTaskPriority,
} from './thunk';
import { IArchive } from '../../common/types';

export interface TaskState {
  data: string;
  taskInfo: any | null;
  taskMessages: any;
  taskFiles: any;
  isLoaded: boolean;
  textLoaded: boolean;
  isFileLoaded: boolean;
  taskText: string;
  archive: IArchive[] | null;
  taskHistory: any;
}

const initialState = {
  data: '',
  taskInfo: null,
  taskText: '',
  textLoaded: false,
  taskMessages: [],
  taskFiles: [],
  isLoaded: false,
  isFileLoaded: false,
  archive: [],
  taskHistory: {
    list: [],
    isLoaded: false,
  },
} as TaskState;

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // clearTaskData: (state) => {
    //   state.isLoaded = false;
    //   state.taskFiles = null;
    //   // state.taskInfo = null;
    //   state.taskText = '';
    //   state.taskHistory = {
    //     list: [],
    //     isLoaded: false,
    //   };
    //   state.archive = [];
    //   state.taskMessages = null;
    // },
    updateMessagesData: (state) => {
      state.taskMessages = [];
    },
    clearTaskData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createTask.fulfilled, (state: TaskState, action) => {
      state.data = action.payload;
    });
    builder.addCase(createTask.rejected, (state: TaskState) => {
      state.data = '';
    });
    builder.addCase(getTaskData.fulfilled, (state: TaskState, action) => {
      state.taskInfo = action.payload;
    });
    builder.addCase(getTaskData.rejected, (state: TaskState) => {
      state.taskInfo = null;
    });
    builder.addCase(getMessagesList.pending, (state: TaskState) => {
      state.isLoaded = true;
    });
    builder.addCase(getMessagesList.fulfilled, (state: TaskState, action) => {
      state.taskMessages = action.payload;
      state.isLoaded = false;
    });
    builder.addCase(getMessagesList.rejected, (state: TaskState) => {
      state.taskMessages = [];
      state.isLoaded = false;
    });
    builder.addCase(getFilesList.pending, (state: TaskState) => {
      state.isFileLoaded = true;
    });
    builder.addCase(getFilesList.fulfilled, (state: TaskState, action) => {
      state.taskFiles = action.payload.fileList;
      state.isFileLoaded = false;
    });
    builder.addCase(getFilesList.rejected, (state: TaskState) => {
      state.taskFiles = [];
    });
    builder.addCase(getArchive.fulfilled, (state: TaskState, action) => {
      state.archive = action.payload.taskList;
      console.log(state.archive);
    });
    builder.addCase(getArchive.rejected, (state: TaskState) => {
      state.archive = [];
    });
    builder.addCase(
      getTaskDescription.fulfilled,
      (state: TaskState, action) => {
        state.taskText = action.payload.data.text;
        state.textLoaded = true;
      }
    );
    builder.addCase(getTaskDescription.pending, (state: TaskState) => {
      state.textLoaded = false;
    });
    builder.addCase(getTaskDescription.rejected, (state: TaskState) => {
      state.taskText = '';
      state.textLoaded = false;
    });
    builder.addCase(getTaskHistory.fulfilled, (state: TaskState, action) => {
      state.taskHistory.list = action.payload.historyList;
      state.taskHistory.isLoaded = true;
    });
    builder.addCase(getTaskHistory.pending, (state: TaskState) => {
      state.taskHistory.isLoaded = false;
    });
    builder.addCase(getTaskHistory.rejected, (state: TaskState) => {
      state.taskHistory.list = [];
      state.taskHistory.isLoaded = false;
    });
    builder.addCase(changePriorityCO.fulfilled, (state: TaskState, action) => {
      const { groupId } = action.meta.arg;
      if (!state.taskInfo.groups) return;
      state.taskInfo.groups.forEach((group: any) => {
        if (group.id === groupId) {
          group.priority = action.meta.arg.priority;
        }
      });
    });
    builder.addCase(
      updateTaskPriority.fulfilled,
      (state: TaskState, action) => {
        state.taskInfo.priority = action.meta.arg.priority;
      }
    );
  },
});

const { reducer } = taskSlice;
export const { clearTaskData, updateMessagesData } = taskSlice.actions;

export default reducer;
