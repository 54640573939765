import { configureStore } from '@reduxjs/toolkit';

import authReducer from './store/auth/store';
import projectReducer from './store/projects/store';
import taskReducer from './store/tasks/store';
import userReducer from './store/user/store';
import billingReducer from './store/billing/store';

const rootReducer = {
  auth: authReducer,
  projectData: projectReducer,
  taskData: taskReducer,
  userData: userReducer,
  billingData: billingReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
