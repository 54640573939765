import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useCallback,
  forwardRef,
} from 'react';
import Tooltip from 'rc-tooltip';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import { useThemeToggler } from '../../context/ThemeContext';
import { checkPermission } from '../../hooks/checkPermission';

import { TaskItem } from './types';
import { TUserData } from '../ProjectList/types';
import { IProjectData } from '../../common/types';

import TaskCard from './TaskCard/TaskCard';
import CreateTask from '../../forms/CreateTask/CreateTask';
import { STAGES_DATA, generalChatType } from '../../common/constant';
import {
  getProjectData,
  getSingleTask,
  getStaffTasks,
  getUsersList,
} from '../../store/projects/thunk';
import { AppDispatch } from '../../store';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import Spinner from '../../components/UI/Spinner/Spinner';
import { setCurrentProjectRoleId } from '../../store/user/store';
import { useSocketListener } from '../../hooks/useSocketListener';
import { getFilesList } from '../../store/tasks/thunk';

import './TaskList.scss';
import { clearProjectInfo } from '../../store/projects/store';
import { getRoleList } from '../../store/user/thunk';
/* eslint-disable */

const TaskList = (): JSX.Element => {
  const labelInputRef = useRef<HTMLInputElement>(null);
  const columnsRef = useRef<any>([]);
  const [isCreateTaskForm, toggleCreateTaskForm] = useState<boolean>(false);
  const { slugTask, slug } = useParams();
  const { pathname } = useLocation();
  const [isPreviewCreateForm, togglePreviewCreateForm] =
    useState<boolean>(true);
  const { theme } = useThemeToggler();
  const { taskInfo } = useSelector(
    (state: { taskData: any }) => state.taskData
  );
  const { projectInfo } = useSelector(
    (state: { projectData: IProjectData }) => state.projectData
  );
  const { userInfo } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [draftTasksVisible, setDraftTasksVisible] = useState<any>({
    isVisible: false,
    once: false,
  });
  const ref = useRef<HTMLLIElement>(null);
  useOnClickOutside(ref, () => {
    if (
      labelInputRef.current &&
      !(labelInputRef.current!.nextElementSibling as HTMLInputElement).value
        .length
    ) {
      togglePreviewCreateForm(true);
    }

    if (isCreateTaskForm && !isInputHaveValueCondition()) {
      toggleCreateTaskForm(false);
    }
  });

  const projectTaskEventHandlerSocket = useCallback(
    (data: any) => {
      if (data.pSlug === slug) {
        dispatch(getProjectData(data.pSlug as string));
      }
    },
    [slug]
  );

  const taskFileEventHandler = useCallback(
    (data: any) => {
      if (data.slug === slug) {
        dispatch(getFilesList({ typeId: data.type, slug: data.slug }));
        dispatch(getProjectData(slug as string));
      }
    },
    [slugTask, taskInfo]
  );
  useEffect(() => {
    checkPermission('STAFF') && dispatch(getStaffTasks());
  }, []);
  // const projectUsersTypeHandlerSocket = useCallback(
  //   (data: any) => {
  //     if (data.slug === slug) {
  //       dispatch(getRoleList());
  //       dispatch(getUsersList(slug as string));
  //       dispatch(getProjectData(slug as string));
  //     }
  //   },
  //   [slug]
  // );

  // useSocketListener('projectEventUser', projectUsersTypeHandlerSocket);
  useSocketListener('projectEvent', projectTaskEventHandlerSocket);
  useSocketListener('taskFile', taskFileEventHandler);

  useLayoutEffect(() => {
    return () => {
      dispatch(clearProjectInfo());
    };
  }, []);

  useLayoutEffect(() => {
    if (slug) {
      dispatch(getProjectData(slug))
        .unwrap()
        .then(() => {
          dispatch(getUsersList(slug as string))
            .unwrap()
            .then((e: any) => {
              if (!userInfo?.id) return;
              dispatch(
                setCurrentProjectRoleId(
                  e?.find((user: TUserData) => user.id === userInfo?.id)?.roleId
                )
              );
            });
        })
        .catch(() => {
          navigate('/');
        });
    }
  }, [slug, userInfo?.id]);

  useEffect(() => {
    const main = document.querySelector('.main');
    const mainTasksClassName = 'main--tasks';
    const isProjectPage = pathname.includes('project');

    if (isProjectPage && !main?.classList.contains(mainTasksClassName)) {
      main?.classList.add(mainTasksClassName);
    }

    return () => {
      main?.classList.remove(mainTasksClassName);
    };
  }, [slug, slugTask]);

  useEffect(() => {
    for (let i = 0, l = columnsRef.current?.length; i < l; i++) {
      const isScroll =
        columnsRef.current[i]?.scrollHeight -
        columnsRef.current[i]?.clientHeight;
      if (isScroll) {
        columnsRef.current[i]?.classList.add('list--scroll--active');
      } else {
        columnsRef.current[i]?.classList.remove('list--scroll--active');
      }
    }
  }, [projectInfo, slugTask, slug, draftTasksVisible.isVisible]);

  useEffect(() => {
    if (isCreateTaskForm || !isPreviewCreateForm) {
      labelInputRef.current?.click();
    }
  }, [isCreateTaskForm, isPreviewCreateForm]);

  useEffect(() => {
    toggleCreateTaskForm(false);
  }, [slugTask]);

  const handlePreviewCreateForm = (): void => {
    togglePreviewCreateForm(false);
  };

  const projectTaskEventHandler = useCallback(
    (data: any) => {
      if (slugTask && slug === data.pSlug) {
        dispatch(getProjectData(data.pSlug as string));
      } else if (slug) {
        dispatch(getProjectData(slug as string));
      } else if (checkPermission('STAFF')) {
        // dispatch(getSingleTask(data.slug));
        dispatch(getStaffTasks());
      }
    },
    [slugTask]
  );

  const handleSocket = useCallback(
    (data: any): void => {
      if (data.type === generalChatType && slug) {
        dispatch(getProjectData(slug as string));
      } else if (taskInfo?.project?.slug) {
        dispatch(getProjectData(taskInfo.project.slug as string));
      }
    },
    [slug, taskInfo]
  );

  useSocketListener('projectTaskEvent', projectTaskEventHandler);
  useSocketListener('taskChat', handleSocket);

  const renderContentCard = (amountElements: boolean): JSX.Element => {
    if (amountElements && isPreviewCreateForm) {
      return (
        <button
          type="button"
          className="task-list__container"
          onClick={handlePreviewCreateForm}
        >
          <span className="task-card__headline task-card__headline--custom">
            Створити нову задачу
          </span>
        </button>
      );
    }

    return (
      <CreateTask
        ref={labelInputRef}
        hideCreateTaskForm={() => {
          toggleCreateTaskForm(false);
        }}
        slug={slug}
        projectId={projectInfo.id}
      />
    );
  };

  const isInputHaveValueCondition = (): string | boolean => {
    if (labelInputRef.current) {
      return (
        labelInputRef.current!.nextElementSibling as HTMLInputElement
      ).value.trim();
    } else return false;
  };

  const renderCustomCard = (amountElements: boolean): JSX.Element | null =>
    isCreateTaskForm || amountElements ? (
      <div ref={ref as any} className="task-list__content">
        {renderContentCard(amountElements)}
      </div>
    ) : null;

  const hastasks = useMemo(() => {
    if (projectInfo.stageList) {
      return projectInfo.stageList.some(
        (item: TaskItem) => item.taskList.length > 0
      );
    }
  }, [projectInfo.stageList]);

  useEffect(() => {
    const container: any = document.querySelector('.main');

    if (!container) return;

    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const mouseDown = (e: MouseEvent) => {
      if (
        !(e.target as any).closest('.list') ||
        (e.target as any).closest('.list__item')
      )
        return;
      isDown = true;
      startX = e.pageX;
      scrollLeft = container.scrollLeft;
      (container as any).style.cursor = 'grabbing';
      (container as any).style.userSelect = 'none';
    };

    const mouseLeave = () => {
      isDown = false;
      (container as any).style.cursor = 'auto';
      (container as any).style.userSelect = 'auto';
    };

    const mouseUp = () => {
      isDown = false;
      (container as any).style.cursor = 'auto';
      (container as any).style.userSelect = 'auto';
    };

    const mouseMove = (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - container.getBoundingClientRect().left;
      const speed = 1;
      const walk = (x - startX) * speed;
      container.scrollLeft = scrollLeft - walk;
    };

    document.addEventListener('mousedown', mouseDown);
    document.addEventListener('mouseleave', mouseLeave);
    document.addEventListener('mouseup', mouseUp);
    document.addEventListener('mousemove', mouseMove);

    return () => {
      document.removeEventListener('mousedown', mouseDown);
      document.removeEventListener('mouseleave', mouseLeave);
      document.removeEventListener('mouseup', mouseUp);
      document.removeEventListener('mousemove', mouseMove);
    };
  }, [slugTask, slug]);

  return (
    <div className="list-wrapper">
      <div className="list-wrapper__content">
        {/* {!Object.keys(projectInfo).length && (
          <div className="screen-loader-wrapper">
            <Spinner size="lg" className="task-loading" />
          </div>
        )} */}
        <Tasks
          theme={theme}
          ref={columnsRef}
          list={projectInfo.stageList}
          hastasks={hastasks}
          onClick={() => {
            if (isCreateTaskForm && isPreviewCreateForm) {
              togglePreviewCreateForm(true);
            } else {
              togglePreviewCreateForm(false);
              toggleCreateTaskForm(true);
            }
          }}
          draft={draftTasksVisible}
          draftCallback={() => {
            setDraftTasksVisible({
              isVisible: true,
              once: true,
            });
          }}
          role={userInfo?.roleId}
          renderCustomCard={renderCustomCard}
          slug={slug}
        />
      </div>
    </div>
  );
};

const Tasks = forwardRef<
  {
    theme: string;
    list: TaskItem[];
    hastasks: boolean;
    onClick: () => void;
    draft: any;
    draftCallback: () => void;
    renderCustomCard: (amountElements: boolean) => JSX.Element | null;
    role: number;
  },
  any
>(
  (
    {
      theme,
      list,
      hastasks,
      onClick,
      draft,
      draftCallback,
      renderCustomCard,
      role,
    },
    ref
  ) => {
    const refCallback = useCallback((node: any) => {
      if (node !== null) {
        (ref as any).current.push(node);
      }
    }, []);

    return (
      <ul
        className={cx('list', 'task-list', {
          'task-list--staff': checkPermission('STAFF'),
        })}
      >
        {list?.map(
          (item: TaskItem): JSX.Element => (
            <li
              key={item.id}
              className={`task-list__item task-list__item--${theme}`}
            >
              <div
                className={`task-card task-card--${theme} task-card__col-head--${theme}`}
              >
                <span
                  className={`task-list__stage task-list__stage--${item.name}`}
                />
                <div className="task-card__flex">
                  <p className="task-card__headline">
                    {STAGES_DATA[item.name]}
                    <span className="task-card__counter">
                      {item.taskList.length}
                    </span>
                  </p>

                  {item.name === 'new' && checkPermission('CLIENT') && (
                    <Tooltip
                      placement="top"
                      trigger={['hover']}
                      overlay={<span>нова задача</span>}
                    >
                      <button
                        type="button"
                        className="task-list__btn"
                        onClick={onClick}
                      >
                        <span className="ci ci-plus" />
                      </button>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div ref={refCallback} className="list--scroll">
                {item.name === 'new' &&
                  checkPermission('CLIENT') &&
                  renderCustomCard(!hastasks as boolean)}
                <TaskCard
                  role={role}
                  key={item.id}
                  draft={draft}
                  tasks={item.taskList}
                  column={item.name}
                  draftCallback={draftCallback}
                />
              </div>
            </li>
          )
        )}
      </ul>
    );
  }
);

export default TaskList;
