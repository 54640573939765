import React, { forwardRef, memo } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { EmojiesBlock } from '../../../components/Emojies/Emojies';

import { IMessage } from '../../../common/types';
import { useThemeToggler } from '../../../context/ThemeContext';

import { ReactComponent as IconEdit } from '../../../media/icons/edit.svg';
import { ReactComponent as IconTrash } from '../../../media/icons/trash_full.svg';
import { ReactComponent as IconSub } from '../../../media/icons/sub_right.svg';

import { isTheSameAuthor } from '../../../helpers/common';
import { emojiesObject } from '../../../helpers/constant';

interface ContextMenuProps {
  message: IMessage;
  isVisible: boolean;
  theme: string;
  userId: number;
  hasTouchScreen?: boolean;
  onEditMessage: (message: IMessage) => void;
  onDeleteMessage: (message: IMessage) => void;
  onReplayMessage: (message: IMessage) => void;
  onSendEmoji: (emoji: string, messageId: string) => void;
}

const ContextMenu = forwardRef(
  (
    {
      message,
      isVisible,
      onEditMessage,
      onReplayMessage,
      onDeleteMessage,
      onSendEmoji,
      theme,
      userId,
      hasTouchScreen,
    }: ContextMenuProps,
    ref
  ) => (
    <div
      ref={ref as any}
      className={cx('context-menu', {
        'context-menu--show': isVisible,
        [`context-menu--${theme}`]: theme,
      })}
    >
      <EmojiesBlock
        emojies={Object.keys(emojiesObject.recently)}
        onClick={onSendEmoji}
        message={message as any}
        hasTouchScreen={hasTouchScreen}
        emojiClassName="context-menu__smile"
        className="context-menu__smiles"
      />
      <ul className="context-menu__list">
        <li
          className="context-menu__item"
          onClick={!hasTouchScreen ? () => onReplayMessage(message) : () => {}}
          onTouchEnd={
            hasTouchScreen ? () => onReplayMessage(message) : () => {}
          }
        >
          <IconSub />
          Відповісти
        </li>
        {message?.canEdit && isTheSameAuthor(userId, message.user.id) && (
          <li
            className="context-menu__item"
            onClick={() => {
              console.log(message);
              onEditMessage(message);
            }}
            // onClick={!hasTouchScreen ? () => onEditMessage(message) : () => {}}
            onTouchEnd={
              hasTouchScreen ? () => onEditMessage(message) : () => {}
            }
          >
            <IconEdit />
            Редагувати
          </li>
        )}
        {message?.canDelete && isTheSameAuthor(userId, message.user.id) && (
          <li
            className="context-menu__item"
            onClick={
              !hasTouchScreen ? () => onDeleteMessage(message) : () => {}
            }
            onTouchEnd={
              hasTouchScreen ? () => onDeleteMessage(message) : () => {}
            }
          >
            <IconTrash />
            Видалити
          </li>
        )}
      </ul>
    </div>
  )
);

export default memo(ContextMenu);
