import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'quill-image-resize-module-react';
import ResizeModule from '@botom/quill-resize-module';
import cx from 'classnames';

import 'tinymce/tinymce';
import 'tinymce/themes/silver';
// import 'tinymce/plugins/paste';
import 'tinymce/models/dom/model';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/media';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/autoresize';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
// import 'tinymce/skins/content/default/content.min.css';
import 'react-quill/dist/quill.snow.css';

import './RichTextEditor.scss';
import { Editor } from '@tinymce/tinymce-react';

// import tinymce from 'tinymce/tinymce';

const { Quill } = ReactQuill;

const Delta = Quill.import('delta');
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');
const Tooltip = Quill.import('ui/tooltip');

Quill.register('modules/resize', ResizeModule);

Quill.register(
  'modules/imageResize',
  require('quill-image-resize-module-react').default
);

// tinymce.init({
//   selector: '#tinymce',
//   skin: false,
//   plugins: ['paste', 'link', 'autoresize'],
// });

// tinymce.init({
//   selector: 'textarea', // change this value according to your HTML
//   plugins: 'lists',
//   toolbar: 'bullist numlist',
//   menu: { tools: { title: 'Tools', items: 'listprops' } },
// });

(window as any).Quill = Quill;

function lineBreakMatcher(): void {
  const newDelta = new Delta();
  newDelta.insert({ break: '' });
  return newDelta;
}

class SmartBreak extends Break {
  length(): any {
    return 1;
  }

  value(): any {
    return '\n';
  }

  insertInto(parent: any, ref: any): void {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

class SmartBreaker {
  static register(): void {
    Quill.register(SmartBreak, true);
  }

  constructor(quill: any, options: any) {
    (this as any).quill = quill;
    (this as any).options = options;

    quill.keyboard.addBinding(
      {
        key: 13,
        shiftKey: true,
      },
      this.enterHandler.bind(this)
    );
    quill.keyboard.bindings[13].unshift(quill.keyboard.bindings[13].pop());
    quill.clipboard.addMatcher('BR', function () {
      let newDelta = new Delta();
      newDelta.insert({ break: '' });
      return newDelta;
    });

    let length = quill.getLength();
    let text = quill.getText(length - 2, 2);
    if (text === '\n\n') {
      quill.deleteText(quill.getLength() - 2, 2);
    }
  }

  enterHandler(range: any): void {
    let currentLeaf = (this as any).quill.getLeaf(range.index)[0];
    let nextLeaf = (this as any).quill.getLeaf(range.index + 1)[0];

    (this as any).quill.insertEmbed(range.index, 'break', true, 'user');

    // Insert a second break if:
    // At the end of the editor, OR next leaf has a different parent (<p>)
    if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
      (this as any).quill.insertEmbed(range.index, 'break', true, 'user');
    }

    // Now that we've inserted a line break, move the cursor forward
    (this as any).quill.setSelection(
      range.index + 1,
      (Quill as any).sources.SILENT
    );
  }
}

// Quill.register('modules/smart-breaker', SmartBreaker);

class CustomTooltip extends Tooltip {
  hide(): void {
    // Save the current scroll position
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    super.hide();

    window.scrollTo(0, scrollPosition);
  }
}
Quill.register('modules/tooltip', CustomTooltip);

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';

Quill.register('formats/break', SmartBreak);

interface IRichTextEditorProps {
  content: string;
  onChangeDescription: (value: string) => void;
  style?: any;
  theme?: string;
  editorName: string;
  readOnly?: boolean;
}

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [
    {
      align: '',
    },
    {
      align: 'center',
    },
    {
      align: 'right',
    },
    {
      align: 'justify',
    },
  ],
  [{ header: [1, 2, 3] }],

  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  ['blockquote', 'code-block'],
  [{ indent: '-1' }, { indent: '+1' }],
  ['link', 'image', 'video'],

  ['clean'],
];

const modules = {
  toolbar: toolbarOptions,

  clipboard: {
    matchVisual: false,
    matchers: [['BR', lineBreakMatcher]],
  },
  imageResize: {
    displaySize: true,
    modules: ['Resize', 'DisplaySize'],
  },
  resize: {
    showToolbar: false,
  },
};

const RichTextEditor = ({
  content,
  editorName,
  theme,
  onChangeDescription,
  style = {},
  readOnly = false,
}: IRichTextEditorProps): JSX.Element => {
  const [value, setValue] = useState<string>(content);
  const [prevValue, setPrevValue] = useState<string>('');
  const [contentUpdatesCount, setContentUpdatesCount] = useState<number>(0);
  const quillRef = useRef(null);
  const editorRef = useRef(null);

  const styles = `html { min-height: 224px!important; } 
                  body { font-size: 15px;min-height: 224px; cursor: auto;font-family: "Noto Sans", sans-serif;
                    ${theme === 'dark' && ' color: #fff !important;'}
                  }
                  img { max-width: 100%;} 
                  .tox-menu { 
                    ${
                      theme === 'dark' &&
                      'background-color: #1e1e1e !important;'
                    }
                    }`;

  useEffect(() => {
    if (content) {
      setValue(content);
      setPrevValue(content);
      setContentUpdatesCount((prev) => prev + 1);
    }
  }, [content]);

  useEffect(() => {
    // setTimeout(() => {
    const tinyEditor = document.getElementById('tinymce_ifr') as any;
    const tinyEditorBody = tinyEditor?.contentWindow?.document?.body;

    const handleBlurPress = (e: any): void => {
      if (value !== content && !e.target.closest('.editor')) {
        onChangeDescription(value);
      }
    };

    window.addEventListener('click', handleBlurPress);

    return () => {
      window.removeEventListener('click', handleBlurPress);
    };
    // }, 2000);
  }, [content, value]);

  // useEffect(() => {
  //   const handleBlurPress = (e: any): void => {
  //     if (!e.target.closest('.quill') && value !== content) {
  //       onChangeDescription(value);
  //     }
  //   };

  //   window.addEventListener('click', handleBlurPress);

  //   return () => {
  //     window.removeEventListener('click', handleBlurPress);
  //   };
  // }, [value, content]);

  useEffect(() => {
    if (!quillRef.current) return;
    const quill = (quillRef as any).current.getEditor();
    const keyboard = quill.getModule('keyboard');
    keyboard.addBinding({ key: 13, shiftKey: true }, (range: any) => {
      const currentLeaf = quill.getLeaf(range.index)[0];
      const nextLeaf = quill.getLeaf(range.index + 1)[0];
      quill.insertEmbed(range.index, 'break', true, 'user');
      if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
        quill.insertEmbed(range.index, 'break', true, 'user');
      }
      // quill.setSelection(range.index + 1, (Quill as any).sources.SILENT);
      quill.setSelection(range.index, (Quill as any).sources.SILENT);
    });
    keyboard.bindings[13].unshift(keyboard.bindings[13].pop());
    quill.clipboard.addMatcher('BR', function () {
      let newDelta = new Delta();
      newDelta.insert({ break: '' });
      return newDelta;
    });

    let length = quill.getLength();
    let text = quill.getText(length - 2, 2);
    console.log('length', quill.getText().length, quill.getText());
    if (text === '\n\n') {
      // if (text === '\n\n') {
      quill.deleteText(quill.getLength() - 2, 2);
    }
  });

  const handleEditorChange = (ct: any, editor: any): any => {
    setValue(ct);
  };

  const isMobile =
    'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0;

  return (
    <div
      className={cx('editor', {
        [`editor--${theme}`]: theme,
      })}
      data-text-editor={editorName}
      style={style}
    >
      {/* <ReactQuill
        ref={quillRef}
        readOnly={readOnly}
        theme="snow"
        value={value}
        onChange={(val) => {
          setValue(val);
        }}
        className={cx({
          [`editor--${theme}`]: theme,
        })}
        modules={modules}
      /> */}
      <Editor
        initialValue={content}
        onInit={(evt, editor) => {
          // editorRef.current = editor;
        }}
        init={{
          menubar: false,
          contextmenu: isMobile && 'image table',
          plugins: [
            'link',
            'lists',
            'image',
            'code',
            'autoresize',
            'advlist',
            'charmap',
            'table',
            'media',
          ],
          toolbar:
            'bold italic underline strikethrough | \
            forecolor backcolor | \
            alignleft aligncenter alignright alignjustify | \
            Blocks | bullist numlist | blockquote code | \
            outdent indent | \
            link image media | removeformat',
          skin: false,
          statusbar: false,
          content_css: false,
          branding: false,
          block_formats: 'Paragraph=p; Header 1=h1;Header 2=h2;Header 3=h3',
          // resize: false,
          autoresize_bottom_margin: 0,
          autoresize_overflow_padding: 0,
          autoresize_on_init: true,
          file_picker_types: 'image code',
          media_live_embeds: true,
          image_advtab: true,
          file_picker_callback: (cb, val, meta) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.addEventListener('change', (e: any) => {
              const file = e.target.files[0];

              const reader = new FileReader();
              reader.addEventListener('load', () => {
                /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                const id = `blobid${new Date().getTime().toString()}`;
                const { blobCache } = (window as any).tinymce.activeEditor
                  .editorUpload;
                const base64 = (reader as any).result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
          content_style: styles,
        }}
        onEditorChange={handleEditorChange}
        disabled={readOnly}
        id="tinymce"
      />
    </div>
  );
};

export default RichTextEditor;
