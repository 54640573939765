import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import dayjs from 'dayjs';

import { useThemeToggler } from '../../../context/ThemeContext';
import UsersList from '../../../components/UsersList/UsersList';
import { checkPermission } from '../../../hooks/checkPermission';
import User from '../../../components/UsersList/User/User';
import {
  STATUS_NEW,
  STATUS_DRAFT,
  DATE_FORMAT,
  GLOBAL_ROLES_COLORS,
  PROJECT_ROLES_COLORS,
  generalChatType,
  PROJECT_ROLES,
} from '../../../common/constant';
import { TaskData } from '../types';
import Button from '../../../components/Button/Button';
import { Skeleton } from '../../../components/UI/Skeleton/Skeleton';

import { AppDispatch } from '../../../store';

import { ReactComponent as IconBlogging } from '../../../media/icons/blogging.svg';
import { ReactComponent as IconClip2 } from '../../../media/icons/paperclip2.svg';
import { ReactComponent as IconOverdue } from '../../../media/icons/remove-date-calendar-icon.svg';
import { ReactComponent as IconList } from '../../../media/icons/list.svg';

import './TaskCard.scss';
import { IProjectData } from '../../../common/types';
import { fetchCoSum, getProjectData } from '../../../store/projects/thunk';
import { clearProjectCoSum } from '../../../store/projects/store';
import { useSocketListener } from '../../../hooks/useSocketListener';

interface IProjectCardProps {
  tasks: TaskData[];
  column: string;
  draftCallback: () => void;
  draft: any;
  defaultOpened?: boolean;
  role: string;
}

const AMOUNT_LEVELS = 4;
const DEFAULT_CLASS_LEVEL = 'task-card__level';
const TASK_TYPE: any = {
  0: 'normal',
  1: 'incident',
};

export default function TaskCard({
  tasks,
  column,
  draftCallback,
  draft,
  role,
}: IProjectCardProps): JSX.Element {
  const [isClicedToOpenTask, setIsClicedToOpenTask] = useState<boolean>(false);
  const { slug, slugTask } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { theme } = useThemeToggler();
  const { onlineUsers } = useSelector(
    (state: { userData: any }) => state.userData
  );
  const { projectsCoSum, projectInfo } = useSelector(
    (state: { projectData: IProjectData }) => state.projectData
  );
  const projectTaskEventHandler = useCallback(
    (data: any) => {
      if (slug === data.pSlug && column === 'pending') {
        dispatch(getProjectData(data.pSlug as string));
      }
    },
    [slugTask, slug]
  );
  useSocketListener('projectTaskEvent', projectTaskEventHandler);

  useLayoutEffect(() => {
    if (!isClicedToOpenTask) {
      dispatch(clearProjectCoSum());
    }
  }, [isClicedToOpenTask]);

  useLayoutEffect(() => {
    if (
      slug &&
      column === 'pending' &&
      role === (PROJECT_ROLES.productOwner as any)
    ) {
      dispatch(fetchCoSum(slug as any));
    }
  }, [slug, role, column, projectInfo]);

  const actualCoSum = useMemo(() => {
    const o: {
      [key: string]: { sum: number };
    } = {};
    if (projectsCoSum?.taskList) {
      projectsCoSum.taskList.forEach((item: any) => {
        const sum = item.groups.reduce(
          (acc: number, group: any) => acc + group.total,
          0
        );
        o[item.id] = { sum };
      });
    }
    return o;
  }, [projectsCoSum]);

  // const [totalCoCount, setTotalCoCount] = useState<number>(0);

  const renderPriority = (priority: number): JSX.Element => {
    const data = Array(AMOUNT_LEVELS).fill(DEFAULT_CLASS_LEVEL);

    for (let i = 0; i < priority; i += 1) {
      data[i] = data[i].replace(
        DEFAULT_CLASS_LEVEL,
        `${DEFAULT_CLASS_LEVEL} ${DEFAULT_CLASS_LEVEL}--custom`
      );
    }

    return (
      <ul className="list task-card__priority">
        {data.map(
          (level: string, index: number): JSX.Element => (
            <li key={`${level}--${index}`} className={level} />
          )
        )}
      </ul>
    );
  };

  const handleToggleModal = (task: TaskData): void => {
    navigate(`/task/${task.slug}/`, { replace: !task.slug ? true : false });
  };

  const tasksDtaftCount = useMemo(
    () =>
      tasks?.filter((task: TaskData) => task.statusId === STATUS_DRAFT).length,
    [tasks, slug, slugTask]
  );

  const tasksNewCount = useMemo(
    () =>
      tasks?.filter((task: TaskData) => task.statusId === STATUS_NEW).length,
    [tasks, slug, slugTask]
  );

  const isNeededShowButton =
    tasksDtaftCount >= 6 && tasksNewCount >= 6 ? true : false;

  const sortedTasksByStatusId = useMemo(
    () =>
      [...tasks]?.sort((a: TaskData, b: TaskData) => {
        if (a.statusId > b.statusId) return 1;
        if (a.statusId < b.statusId) return -1;
        return 0;
      }),
    [tasks]
  );

  const sortedTasksByDate = useMemo(
    () =>
      [...sortedTasksByStatusId]?.sort((a: TaskData, b: TaskData) => {
        if (a.dateCreate < b.dateCreate) return 1;
        if (a.dateCreate > b.dateCreate) return -1;
        return 0;
      }),
    [sortedTasksByStatusId]
  );

  return (
    <ul className="list">
      {tasks?.map((task: TaskData) => {
        if (
          task.statusId === STATUS_DRAFT &&
          !draft.isVisible &&
          isNeededShowButton
        ) {
          return null;
        }

        return (
          <li key={task.id} className="list__item">
            <button
              className="task-card__content"
              onClick={() => {
                setIsClicedToOpenTask(true);
                return handleToggleModal(task);
              }}
            >
              <div
                className={`task-card task-card--${task.statusId} task-card--${theme} task-card--${task?.bgColorClass}`}
              >
                {task.priority && renderPriority(task.priority)}
                <div className="task-card__flex">
                  <div className="task-card__block">
                    <span className="task-card__id">{`#${task.id}`}</span>{' '}
                    <User
                      isOnline={false}
                      user={task.user}
                      backgroundColor={
                        theme === 'dark'
                          ? '#828588'
                          : GLOBAL_ROLES_COLORS[task.user.groupId]
                      }
                      size="xs"
                    />
                    <span className="task-card__text">
                      {dayjs
                        .unix(
                          task.statusId === STATUS_NEW
                            ? task.dateDraft
                            : task.dateCreate
                        )
                        .format('DD.MM.YYYY')}
                    </span>
                  </div>
                  {!!task?.isOverdue && (
                    <span className="task-card__overdue">
                      <IconOverdue className="task-card__overdue-icon task-card__icon" />
                    </span>
                  )}
                  {task.type !== 0 && (
                    <Tooltip placement="top" overlay={<span>Негайно!</span>}>
                      <span className="task-card__incident" />
                    </Tooltip>
                  )}
                </div>
                {!checkPermission('CLIENT') && (
                  <h3 className="task-card__project">{task.project}</h3>
                )}

                <h4 className="task-card__title">{task.name}</h4>
                <div className="task-card__details">
                  <div className="task-card__info">
                    {!!task?.files && (
                      <span className="task-card__attached-files">
                        <IconClip2 className="task-card__attached-files-icon task-card__icon" />
                        {task.files}
                      </span>
                    )}
                    {!!task?.countCo && (
                      <span className="task-card__attached-files">
                        <IconList className="task-card__attached-files-icon" />
                        {task?.countCo}
                      </span>
                    )}
                    {(!!task?.messageCounterTask ||
                      task?.commentatorList?.length) && (
                      <IconBlogging className="task-card__attached-files-icon" />
                    )}
                    {/* {!!task.messageCounterTask && (
                      <div className="task-card__counter task-card__counter--bottom">
                        {task.messageCounterTask}
                      </div>
                    )} */}
                    {task.commentatorList?.map((user: any) => (
                      <Tooltip
                        key={user.id + user.dateCreate}
                        placement="top"
                        trigger={['hover']}
                        overlay={
                          <div>
                            {user.firstName} {user.lastName}
                            <p>
                              {dayjs
                                .unix(user.dateCreate)
                                .format('DD.MM.YYYY HH:mm:ss')}
                            </p>
                          </div>
                        }
                      >
                        <div style={{ flexShrink: 0, marginRight: '2px' }}>
                          <User
                            isOnline={
                              onlineUsers?.find(
                                (item: any) => item.id === user.id
                              )?.isOnline
                            }
                            key={user.id}
                            user={user}
                            size="xs"
                            style={{
                              boxSizing: 'content-box',
                            }}
                            backgroundColor={
                              user.roleId
                                ? PROJECT_ROLES_COLORS[user.roleId]
                                : GLOBAL_ROLES_COLORS[user.groupId]
                            }
                          />
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
                {column !== 'new' && task?.hasPrices ? (
                  <div className="task-card__all-co">
                    {actualCoSum[task.id] ? (
                      typeof actualCoSum[task.id]?.sum === 'number' ? (
                        <b>{`${Number(actualCoSum[task.id]?.sum)} грн`}</b>
                      ) : null
                    ) : (
                      typeof actualCoSum[task.id]?.sum ===
                        ('undefined' as any) && (
                        <Skeleton
                          count={1}
                          width="80px"
                          height="16px"
                          className="task-card__all-co-skeceton"
                        />
                      )
                    )}
                  </div>
                ) : null}
                {task.developerList?.length && (
                  <div className="task-card__details">
                    <div className="task-card__info">
                      <span>Співробітники:</span>
                      <UsersList size="sm" usersArray={task.developerList} />
                    </div>
                  </div>
                )}
                {/* {column === 'inProcess' ? (
                  <>
                    <span
                      className="task-card__bottom-text"
                      style={{ alignSelf: 'flex-start' }}
                    >
                      {task.percent}%
                    </span>
                    <span
                      className="task-card__progress"
                      style={{ width: `${task.percent}%` }}
                    />
                    <span className="task-card__line" />
                  </>
                ) : null} */}
                {task?.percent ? (
                  <>
                    <span
                      className="task-card__bottom-text"
                      style={{ alignSelf: 'flex-start' }}
                    >
                      {task.percent}%
                    </span>
                    <span
                      className="task-card__progress"
                      style={{ width: `${task.percent}%` }}
                    />
                    <span className="task-card__line" />
                  </>
                ) : null}
              </div>
            </button>
          </li>
        );
      })}
      {tasks.find((task: TaskData) => task.statusId === STATUS_DRAFT) &&
        !draft.isVisible &&
        isNeededShowButton && (
          <li className="list__item">
            <Button
              className="task-card__button-draft"
              onClick={draftCallback}
              view="third"
            >
              Опубліковані
            </Button>
          </li>
        )}
    </ul>
  );
}
