import { createAsyncThunk } from '@reduxjs/toolkit';
import ProjectService from '../../services/project.service';
import { TCreateProjectInputs } from '../../forms/CreateProject/types';

type TUpdateNameProject = {
  name: string;
  slug: string;
  projectId?: number;
};

export const fetchProjectList = createAsyncThunk('project/list', async () => {
  try {
    return await ProjectService.getList();
  } catch (error) {
    return error;
  }
});

export const createProject = createAsyncThunk(
  'project/new',
  async (data: TCreateProjectInputs, thunkAPI) => {
    try {
      return await ProjectService.createProject(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateNameProject = createAsyncThunk(
  'project/updateName',
  async (data: TUpdateNameProject, thunkAPI) => {
    try {
      return await ProjectService.updateNameProject(
        { name: data.name },
        data.slug
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProject = createAsyncThunk(
  'project/update',
  async (data: any, thunkAPI) => {
    try {
      return await ProjectService.updateProject(data, data.slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProjectData = createAsyncThunk(
  'project/getInfo',
  async (slug: string, thunkAPI) => {
    try {
      return await ProjectService.getProjectData(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSingleTask = createAsyncThunk(
  'task/getSingleTask',
  async (slug: string, thunkAPI) => {
    try {
      return await ProjectService.getSingleTask(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getStaffTasksArchive = createAsyncThunk(
  'project/getStaffTasksArchive',
  async () => {
    try {
      return await ProjectService.getStaffTasksArchive();
    } catch (error) {
      return error;
    }
  }
);

export const getStaffTasks = createAsyncThunk(
  'project/getStaffTasks',
  async () => {
    try {
      return await ProjectService.getStaffTasks();
    } catch (error) {
      return error;
    }
  }
);

export const deleteProject = createAsyncThunk(
  'project/delete',
  async (slug: string, thunkAPI) => {
    try {
      return await ProjectService.deleteProject(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserTasksList = createAsyncThunk(
  'project/getUserTasksList',
  async () => {
    try {
      return await ProjectService.getUserTasksList();
    } catch (error) {
      return error;
    }
  }
);

export const getUsersList = createAsyncThunk(
  'project/getUsersList',
  async (slug: string, thunkAPI) => {
    try {
      return await ProjectService.getUsersList(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addUserToProject = createAsyncThunk(
  'project/addUser',
  async (data: any, thunkAPI) => {
    try {
      return await ProjectService.addUserToProject(
        { email: data.email },
        data.slug
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changeUserRole = createAsyncThunk(
  'project/changeUserRole',
  async (data: any, thunkAPI) => {
    try {
      return await ProjectService.changeUserRole(
        { role: data.role, userId: data.userId, email: data.email },
        data.slug
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUserFromProject = createAsyncThunk(
  'project/deleteUser',
  async (data: any, thunkAPI) => {
    try {
      return await ProjectService.deleteUserFromProject(
        { userId: data.userId, email: data.email },
        data.slug
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNotificationList = createAsyncThunk(
  'informer/list',
  async () => {
    try {
      return await ProjectService.getNotificationList();
    } catch (error) {
      return error;
    }
  }
);

export const setNotificationViewed = createAsyncThunk(
  'informer/save',
  async (id: number, thunkAPI) => {
    try {
      return await ProjectService.setNotificationViewed(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const setNotificationsViewed = createAsyncThunk(
  'informer/saveAll',
  async () => {
    try {
      return await ProjectService.setNotificationsViewed();
    } catch (error) {
      return error;
    }
  }
);

export const getProjectArchive = createAsyncThunk(
  'project/archive',
  async (slug: string, thunkAPI) => {
    try {
      return await ProjectService.getProjectArchive(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sortProjects = createAsyncThunk(
  'project/sort',
  async (projects: any, thunkAPI) => {
    try {
      return await ProjectService.sortProjects(projects);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchCoSum = createAsyncThunk(
  'project/coSum',
  async (slug: string, thunkAPI) => {
    try {
      return await ProjectService.getCoSum(slug);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
