import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { useThemeToggler } from '../../context/ThemeContext';
import { AppDispatch } from '../../store';
import { clearProjectInfo } from '../../store/projects/store';

import Task from '../../forms/Task/Task';
import Header from '../Header/Header';
import { socket } from '../../socket.js';
import { removeOnlineUser, setOnlineUsers } from '../../store/user/store';
import { getLocalStorage } from '../../helpers/common';
import { getFilesRules, getUserData } from '../../store/user/thunk';
import { getNotificationList } from '../../store/projects/thunk';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useWakeUpMonitor from '../../hooks/useWakeUpMonitor';

export default function Layout(): JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  const { theme } = useThemeToggler();
  const location = useLocation();
  const { slug } = useParams();
  const { taskInfo } = useSelector(
    (state: { taskData: any }) => state.taskData
  );
  const user = useMemo(() => getLocalStorage('user') as any, []);

  const handleWakeUp = useCallback(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useWakeUpMonitor(handleWakeUp);

  useLayoutEffect(() => {
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    const { body } = document;
    if (body) {
      body.classList.add(`theme--${theme}`);
    }
    return () => {
      if (body) {
        body.classList.remove(`theme--${theme}`);
      }
    };
  }, [theme]);

  useEffect(() => {
    if (user) {
      dispatch(getFilesRules());
    }
  }, [user]);

  useEffect(() => {
    if (
      location.pathname.includes('/project') &&
      slug !== taskInfo?.project.slug
    ) {
      dispatch(clearProjectInfo());
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    socket.emit('init', { user: user?.id, availible: true });
  }, [location.pathname]);

  useEffect(() => {
    socket.on('online', (data: any) => {
      const { userId, availible } = data;
      if (availible) {
        dispatch(
          setOnlineUsers({
            id: userId,
            isOnline: availible,
          })
        );
      } else {
        dispatch(removeOnlineUser(userId));
      }
    });
  }, []);

  return (
    <div className={`section section--${theme}`}>
      <Header />

      <main className="main">
        <Outlet />
      </main>
    </div>
  );
}
