import { createSlice } from '@reduxjs/toolkit';
import {
  login,
  logout,
  createAccount,
  restorePassword,
  getNicknames,
  getSpecialities,
  createStaffAccount,
} from './thunk';
import { IUserData } from '../../common/types';

const user = JSON.parse(<string>localStorage.getItem('user'));

export interface IAuth {
  isLoggedIn: boolean;
  user: IUserData | null;
  nicknames?: Record<string, string[]>;
  specialities?: string[];
}

const initialState: IAuth = user
  ? { isLoggedIn: true, user, nicknames: {}, specialities: [] }
  : { isLoggedIn: false, user: null, nicknames: {}, specialities: [] };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createAccount.fulfilled, (state: IAuth, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    });
    builder.addCase(createAccount.rejected, (state: IAuth) => {
      state.isLoggedIn = false;
    });
    builder.addCase(createStaffAccount.fulfilled, (state: IAuth, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    });
    builder.addCase(login.fulfilled, (state: IAuth, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    });
    builder.addCase(login.rejected, (state: IAuth) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(restorePassword.fulfilled, (state: IAuth) => state);
    builder.addCase(restorePassword.rejected, (state: IAuth) => state);
    builder.addCase(logout.fulfilled, (state: IAuth) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(getNicknames.fulfilled, (state: IAuth, action) => {
      state.nicknames = {
        nicFirstname: action.payload.data.data.nicFirstname,
        nicLastname: action.payload.data.data.nicLastname,
      };
    });
    builder.addCase(getSpecialities.fulfilled, (state: IAuth, action) => {
      state.specialities = action.payload.data.data;
    });
  },
});

export const { clearAuthData } = authSlice.actions;
const { reducer } = authSlice;

export default reducer;
