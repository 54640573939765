import { useEffect, useState } from 'react';

const useWakeUpMonitor = (onWakeUp: any): null => {
  const [lastActivity, setLastActivity] = useState(performance.now());

  useEffect(() => {
    const checkIdleTime = (): void => {
      const currentTime = performance.now();
      const timeSinceLastActivity = currentTime - lastActivity;

      if (timeSinceLastActivity > 30000) {
        if (onWakeUp) {
          onWakeUp();
        }
      }

      setLastActivity(currentTime);
    };

    const intervalId = setInterval(checkIdleTime, 5000);

    return () => clearInterval(intervalId);
  }, [lastActivity, onWakeUp]);

  return null;
};

export default useWakeUpMonitor;
