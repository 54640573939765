import { createSlice } from '@reduxjs/toolkit';
import {
  getFilesRules,
  getRoleList,
  getUserData,
  updateAvatar,
  updateUserInfo,
} from './thunk';
import { getLocalStorage, setLocalStorage } from '../../helpers/common';
import { changeUserRole } from '../projects/thunk';

interface UserState {
  roleList: string[];
  onlineUsers: any[];
  userInfo: any;
  userSettings: any;
  fileRules: any;
}

const userData = JSON.parse(<string>localStorage.getItem('user')) || {};

const { tgMyTask, tgOtherTask, mailMyTask, mailOtherTask, doubleSpaces } =
  userData;

const defaultUserSettings = {
  tgMyTask,
  tgOtherTask,
  mailMyTask,
  mailOtherTask,
  doubleSpaces,
};

const initialState = {
  roleList: [],
  onlineUsers: [],
  userInfo: { ...userData },
  userSettings: defaultUserSettings,
  fileRules: {},
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setOnlineUsers(state: UserState, action) {
      const isInState = state.onlineUsers.find(
        (user) => user.id === action.payload.id
      );
      if (!isInState) {
        state.onlineUsers.push(action.payload);
      }
    },
    removeOnlineUser(state: UserState, action) {
      state.onlineUsers = state.onlineUsers.filter(
        (user) => user.id !== action.payload
      );
    },
    getUserInfo(state: UserState) {
      state.userInfo = getLocalStorage('user');
    },
    setCurrentProjectRoleId(state: UserState, action) {
      state.userInfo.roleId = action.payload;
      // setLocalStorage('user', state.userInfo);
    },
    clearAllUserData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRoleList.fulfilled, (state: UserState, action) => {
      state.roleList = action.payload.roleList;
    });
    builder.addCase(getRoleList.rejected, (state: UserState) => {
      state.roleList = [];
    });
    builder.addCase(updateUserInfo.fulfilled, (state: UserState, action) => {
      const { arg } = action.meta;
      state.userInfo = { ...state.userInfo, ...{ [arg!.param]: arg!.value } };
      setLocalStorage('user', state.userInfo);
    });
    builder.addCase(updateAvatar.fulfilled, (state: UserState, action) => {
      const { url } = (action.payload as any).data;
      if (state.userInfo.avatar?.url) {
        state.userInfo.avatar.url = url;
        setLocalStorage('user', state.userInfo);
      }
    });
    builder.addCase(getUserData.fulfilled, (state: UserState, action) => {
      const prevRole = userData.roleId;
      state.userInfo = {
        ...userData,
        ...action.payload.data.user,
        roleId: prevRole,
      };
      setLocalStorage('user', state.userInfo);
    });
    builder.addCase(changeUserRole.fulfilled, (state: UserState, action) => {
      if (action.payload.data?.roleId) {
        state.userInfo.userId = action.payload.data.userId;
      }
      setLocalStorage('user', state.userInfo);
    });
    builder.addCase(getFilesRules.fulfilled, (state: UserState, action) => {
      state.fileRules = action.payload.data;
    });
  },
});

const { reducer } = userSlice;

export const {
  setOnlineUsers,
  removeOnlineUser,
  getUserInfo,
  setCurrentProjectRoleId,
  clearAllUserData,
} = userSlice.actions;

export default reducer;
