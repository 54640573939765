import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';

import { AppDispatch } from '../../store';
import AutoResizeTextarea from '../../forms/AutoResizeTextarea/AutoResizeTextarea';
import Modal from '../../components/Modal/Modal';
import Balance from '../../components/Balance/Balance';
import EditUsersList from '../EditUsersList/EditUsersList';
import Input from '../../components/UI/Input/Input';
import { checkPermission } from '../../hooks/checkPermission';
import {
  deleteProject,
  fetchProjectList,
  getProjectArchive,
  getProjectData,
  getStaffTasksArchive,
  getUsersList,
  updateNameProject,
} from '../../store/projects/thunk';
import { IArchive, IProjectData } from '../../common/types';
import { TUserData } from '../ProjectList/types';

import {
  balanceToProject,
  fetchBalance,
  fetchProjectBalance,
  fetchProjectBalanceStatistic,
  fetchProjectBalanceTransactions,
} from '../../store/billing/thunk';

import { ReactComponent as IconCard } from '../../media/icons/credit_card.svg';
import { ReactComponent as IconFolder } from '../../media/icons/folder_open.svg';

import Button from '../../components/Button/Button';
import BalanceOperations from '../../components/BalanceOperations/BalanceOperations';

import './SettingsPanel.scss';
import { projectChartLabels } from '../../helpers/constant';
import { useThemeToggler } from '../../context/ThemeContext';
import { PROJECT_ROLES, projectTansactionTypes } from '../../common/constant';
import { inputNumberHandler } from '../../helpers/common';
import { useSocketListener } from '../../hooks/useSocketListener';
import Heading from '../../components/UI/Heading/Heading';
import { Payment } from '../Header/Header';
import { clearActualTransactions } from '../../store/billing/store';
import { getRoleList } from '../../store/user/thunk';
import { setCurrentProjectRoleId } from '../../store/user/store';

const transactionsPerView = 5;

export default function SettingsPanel(): JSX.Element {
  const titleRef = useRef<HTMLDivElement>(null);
  const [lastTransacionId, setLastTransacionId] = useState(null);
  const dispatch: AppDispatch = useDispatch();
  const { projectInfo, usersList } = useSelector(
    (state: { projectData: IProjectData }) => state.projectData
  );
  const { taskInfo } = useSelector((state: any) => state.taskData);
  const { userInfo } = useSelector((state: any) => state.userData);
  const { theme } = useThemeToggler();
  const { slug, slugTask } = useParams<{ slug: string; slugTask: string }>();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalBalance, setIsShowModalBalance] = useState(false);
  const [ballanceToProject, setBallanceToProject] = useState(false);
  const {
    projectBalance,
    actualTransactions,
    projectStatistic,
    isTheLastTransaction,
  } = useSelector((state: any) => state.billingData);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const projectEventHandler = useCallback(
    () =>
      (data: any): void => {
        dispatch(fetchProjectList());
        if (data.slug === slug) {
          dispatch(getProjectData(slug as string));
        }
      },
    [slugTask, slug]
  );

  const projectUsersTypeHandlerSocket = useCallback(
    (data: any) => {
      const currentSlug = slug || taskInfo?.project?.slug;
      if (data.slug === currentSlug) {
        dispatch(getRoleList());
        dispatch(getProjectData(currentSlug as string))
          .unwrap()
          .then(() => {
            dispatch(getUsersList(currentSlug as string))
              .unwrap()
              .then((e: any) => {
                if (!userInfo?.id) return;
                const role = e?.find(
                  (user: TUserData) => user.id === userInfo?.id
                )?.roleId;
                dispatch(setCurrentProjectRoleId(role));
                if (isShowModalBalance) {
                  setIsShowModalBalance(false);
                }
              });
          });
      }
    },
    [slug, taskInfo?.project?.slug, userInfo?.id, slugTask, isShowModalBalance]
  );

  useSocketListener('projectEventUser', projectUsersTypeHandlerSocket);
  useSocketListener('projectEvent', projectEventHandler);

  // useEffect(() => {
  //   if (isShowModalBalance && userInfo?.roleId === 4) {
  //     setIsShowModalBalance(false);
  //   }
  // }, [isShowModalBalance, userInfo?.roleId]);

  const handleChangeNameProject = useCallback(
    (value: string): void => {
      if (value.trim() === projectInfo.name) return;

      dispatch(updateNameProject({ name: value, slug: slug as string }));
    },
    [dispatch, projectInfo.name]
  );

  const handleUploadTransactions = useCallback(() => {
    setLastTransacionId(actualTransactions[actualTransactions.length - 1].id);
    dispatch(
      fetchProjectBalanceTransactions({
        slug: slug as string,
        lastId: lastTransacionId,
      })
    );
  }, [dispatch, projectInfo.id]);

  const currentUser = usersList.find(
    (user: TUserData) => user.id === userInfo.id
  );

  const filteredProjectStatistic = useMemo(
    () =>
      projectStatistic?.statistic?.chart.map((item: any) => ({
        work: item.work,
      })),
    [projectStatistic]
  );

  const balanceToProjectHandler = useCallback(
    (value: any) => {
      const res = dispatch(
        balanceToProject({
          slug,
          sum: value,
        })
      );
      res.unwrap().then((data) => {
        if (data?.data?.success) {
          setBallanceToProject(false);
          return res;
        }
      });

      return res;
    },
    [dispatch, slug, lastTransacionId]
  );

  const getProjectBalancesHandler = (): void => {
    setIsShowModalBalance(true);
    if (userInfo.roleId === PROJECT_ROLES.productOwner) {
      dispatch(fetchProjectBalanceStatistic(slug as any));
      dispatch(fetchProjectBalance(slug as any));
      dispatch(
        fetchProjectBalanceTransactions({
          slug: slug as string,
          lastId: lastTransacionId,
        })
      );
    }
  };

  return (
    <div
      className={cx('section__header settings-panel', {
        [`settings-panel--${theme}`]: theme,
      })}
    >
      {checkPermission('CLIENT') && projectInfo.name && (
        <AutoResizeTextarea
          ref={titleRef}
          handleChangeName={handleChangeNameProject}
          name={projectInfo?.name}
          className="section__input-title"
          defaultHeight={projectInfo?.name.length > 150 ? 160 : 30}
        />
      )}

      <div className="section__container" style={{ marginLeft: 'auto' }}>
        {!isMobile && (
          <div className="section__users-wrapper">
            {checkPermission('CLIENT') && (
              <EditUsersList
                projectId={projectInfo.id}
                slug={slug as string}
                usersList={usersList}
              />
            )}
          </div>
        )}
        {checkPermission('CLIENT') && (
          <>
            {!isMobile &&
              currentUser?.roleId === PROJECT_ROLES.productOwner && (
                <div
                  className="settings-panel__item"
                  onClick={getProjectBalancesHandler}
                >
                  <IconCard className="settings-panel__icon" />
                  <Balance
                    className="settings-panel__balance"
                    money={
                      projectInfo?.balance === null ? '0' : projectInfo?.balance
                    }
                  />
                </div>
              )}
            <Modal
              title="Поповнення балансу проєкту"
              isActive={ballanceToProject}
              size="md"
              handleClose={() => {
                setBallanceToProject(false);
              }}
              leftButton={false}
              headerModalClassName="settings-panel__modal-balance"
              modalClassName="settings-panel__modal"
            >
              <Payment paymentHandler={balanceToProjectHandler} />
            </Modal>
            <Modal
              title="Баланс проекту"
              isActive={isShowModalBalance && userInfo?.roleId === 4}
              size="lg"
              handleClose={() => {
                setIsShowModalBalance(false);
                dispatch(clearActualTransactions());
              }}
              leftButton={false}
              headerModalClassName="settings-panel__modal-header"
            >
              <BalanceOperations
                transactionTypes={projectTansactionTypes}
                onRefill={() => {
                  setBallanceToProject(true);
                }}
                onShowMore={() => {
                  dispatch(
                    fetchProjectBalanceTransactions({
                      slug: slug as string,
                      lastId:
                        actualTransactions?.transactionList[
                          actualTransactions?.transactionList?.length - 1
                        ]?.id,
                    })
                  );
                }}
                isLast={!isTheLastTransaction}
                balance={projectBalance}
                transactions={actualTransactions}
                statistic={filteredProjectStatistic}
                chartLabels={projectChartLabels}
              />
            </Modal>
          </>
        )}
        <button
          onClick={() => setIsShowModal(true)}
          type="button"
          className="section__icon-btn"
        >
          <span className="ci ci-menu_alt_05" />
        </button>
      </div>
      <Modal
        title="Меню"
        isActive={isShowModal}
        size="sm"
        handleClose={() => setIsShowModal(false)}
        leftButton={false}
        headerModalClassName="settings-panel__modal-header"
      >
        <ProjectSettings
          usersList={usersList}
          titleRef={titleRef}
          handleChangeNameProject={handleChangeNameProject}
          setIsShowModalBalance={setIsShowModalBalance}
          isMobile={isMobile}
        />
      </Modal>
    </div>
  );
}

const ProjectSettings = ({
  usersList,
  handleChangeNameProject,
  setIsShowModalBalance,
  titleRef,
  isMobile,
  balance,
}: any): JSX.Element => {
  const [settingIsOpen, setSettingIsOpen] = useState(false);
  const [archiveIsOpen, setArchiveIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const { projectInfo } = useSelector(
    (state: { projectData: IProjectData }) => state.projectData
  );
  const { userInfo } = useSelector((state: any) => state.userData);
  const [projectName, setProjectName] = useState(projectInfo.name);
  const dispatch: AppDispatch = useDispatch();
  const params = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { theme } = useThemeToggler();

  const deleteProjectHandler = useCallback(() => {
    dispatch(deleteProject(params.slug as string))
      .unwrap()
      .then(() => {
        dispatch(fetchProjectList());
        navigate('/');
      });
  }, [dispatch, params.slug]);

  const getBalancesHandler = (): void => {
    setIsShowModalBalance(true);
    if (userInfo.roleId === PROJECT_ROLES.productOwner) {
      dispatch(fetchProjectBalanceStatistic(params.slug as any));
      dispatch(fetchProjectBalance(params.slug as any));
      dispatch(
        fetchProjectBalanceTransactions({
          slug: params.slug as string,
          lastId: 0,
        })
      );
    }
  };

  const updateprojectNameHandler = useCallback(() => {
    dispatch(
      updateNameProject({
        name: projectName,
        slug: params.slug as string,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getProjectData(params.slug as string));
      });
    setSettingIsOpen(false);
  }, [dispatch, projectName, params.slug]);

  return (
    <div
      className={cx('project-settings', {
        [`project-settings--${theme}`]: theme,
      })}
    >
      <div className="project-settings__content">
        {isMobile && (
          <div className="project-settings__top">
            {/* <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                marginBottom: '5px',
              }}
              onClick={getBalancesHandler}
            >
              <IconCard />
              <Balance money={balance} />
            </div> */}
            {checkPermission('CLIENT') && projectInfo.name ? (
              <AutoResizeTextarea
                ref={titleRef}
                handleChangeName={handleChangeNameProject}
                name={projectInfo?.name}
                className="section__input-title"
                defaultHeight={projectInfo?.name.length > 150 ? 160 : 30}
              />
            ) : (
              'Завдання'
            )}
            <div className="section__users-wrapper section__users-wrapper--mobile">
              {checkPermission('CLIENT') && (
                <EditUsersList
                  projectId={projectInfo.id}
                  slug={params.slug as string}
                  usersList={usersList}
                />
              )}
            </div>
          </div>
        )}
        <div
          onClick={() => setArchiveIsOpen(true)}
          className="project-settings__item"
        >
          <IconFolder />
          <span>Архів</span>
        </div>
        {checkPermission('CLIENT') && (
          <div
            onClick={() => setSettingIsOpen(true)}
            className="project-settings__item"
          >
            <IconCard />
            <span>Налаштування проекту</span>
          </div>
        )}
      </div>
      {checkPermission('CLIENT') && (
        <>
          <Modal
            title="Налаштування проекту"
            isActive={settingIsOpen}
            size="sm"
            handleClose={() => {
              setSettingIsOpen(false);
              getProjectData(params.slug as string);
            }}
            leftButton={false}
            bodyClassName="project-settings__modal-body"
          >
            <div className="project-settings__content">
              <div className="project-settings__form">
                <div className="project-settings__title">Назва проекту</div>
                <Input
                  value={projectName}
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value.trim();
                    if (val.length === 0 || val === projectInfo.name) return;
                    setProjectName(val);
                  }}
                />
              </div>

              <Button
                view="default"
                onClick={updateprojectNameHandler}
                className="project-settings__button-save"
              >
                Зберегти
              </Button>

              <div
                onClick={() => {
                  setDeleteIsOpen(true);
                }}
                className="project-settings__button-delete"
              >
                Закрити проєкт
              </div>
            </div>
          </Modal>
          <Modal
            headerAccentBackground
            title="Закриття проєкту"
            isActive={deleteIsOpen}
            size="sm"
            handleClose={() => {
              setDeleteIsOpen(false);
            }}
            leftButton={false}
            bodyClassName="project-settings__modal-body"
          >
            <div className="project-settings__content">
              <div className="project-settings__form">
                <div
                  className={cx('project-settings__title', {
                    [`project-settings__title--${theme}`]: theme,
                  })}
                >
                  Ви можете знаходити й повторно відкривати закриті проєкти на
                  сторінці <b>Архіву</b>
                </div>

                <Button
                  onClick={deleteProjectHandler}
                  variant="fill"
                  view="warning"
                  className="project-settings__button-save"
                >
                  Закрити проєкт
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
      <Modal
        title="Архів"
        isActive={archiveIsOpen}
        size="lg"
        handleClose={() => {
          setArchiveIsOpen(false);
        }}
        leftButton={false}
        bodyClassName="project-settings__modal-body"
      >
        <div className="project-settings__content">
          <Archive />
        </div>
      </Modal>
    </div>
  );
};

const Archive = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useThemeToggler();
  const params = useParams();
  const { projectArchive } = useSelector((state: any) => state.projectData);

  useEffect(() => {
    if (params.slug) {
      if (checkPermission('CLIENT')) {
        dispatch(getProjectArchive(params.slug as string));
      }
    } else if (
      checkPermission('STAFF') &&
      (params as any)['*'].includes('tasks')
    ) {
      console.log('params.slug', params);

      dispatch(getStaffTasksArchive());
    }
  }, [params.slug, params.slugtask]);

  return (
    <div
      className={cx('project-settings__archive archive-block', {
        [`archive-block--${theme}`]: theme,
      })}
    >
      {projectArchive?.taskList && projectArchive?.taskList.length ? (
        <div className="archive-block__content">
          <div className="archive-block__header">
            <div className="archive-block__col archive-block__col--name">
              Назва
            </div>
            <div className="archive-block__col archive-block__col--date-start">
              дата створення
            </div>
            <div className="archive-block__col archive-block__col--date-end">
              дата завершення
            </div>
            <div className="archive-block__col archive-block__col--rating">
              Оцінка
            </div>
            <div className="archive-block__col archive-block__col--comment">
              Відгук
            </div>
          </div>
          <div className="archive-block__body">
            {projectArchive?.taskList?.map(
              ({ name, dateCreate, dateFinish, id, slug }: IArchive) => (
                <div
                  key={id}
                  className="archive-block__row"
                  onClick={() => {
                    navigate(`/task/${slug}/`);
                  }}
                >
                  <div className="archive-block__col archive-block__col--name">
                    {name}
                  </div>
                  <div className="archive-block__col archive-block__col--date-start">
                    {dayjs.unix(dateCreate).format('DD.MM.YYYY, HH:mm')}
                  </div>
                  <div className="archive-block__col archive-block__col--date-end">
                    {dayjs.unix(dateFinish).format('DD.MM.YYYY, HH:mm')}
                  </div>
                  <div className="archive-block__col archive-block__col--rating">
                    -
                  </div>
                  <div className="archive-block__col archive-block__col--comment">
                    -
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        'Архів пустий'
      )}
    </div>
  );
};
