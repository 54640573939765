import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SITE_TITLE } from '../common/constant';
import { TaskState } from '../store/tasks/store';
import { IProjectData } from '../common/types';

const useDocumentTitle = (): void => {
  const location = useLocation();
  const params = useParams();
  const { taskInfo } = useSelector(
    (state: { taskData: TaskState }) => state.taskData
  );
  const { projectInfo } = useSelector(
    (state: { projectData: IProjectData }) => state.projectData
  );

  useEffect(() => {
    let title = '';
    const [_, what] = location.pathname.split('/');
    const text = what.charAt(0).toUpperCase() + what.slice(1);
    if (params.slug && projectInfo?.name) {
      title = `${text} - ${projectInfo?.name}`;
    } else if (params.slugTask && taskInfo?.name) {
      title = `${text} - ${taskInfo?.name}`;
    } else if (!taskInfo?.name && projectInfo?.name) {
      title = `Project - ${projectInfo?.name}`;
    } else {
      title = SITE_TITLE;
    }
    document.title = title;
  }, [
    location.pathname,
    taskInfo?.name,
    projectInfo?.name,
    params.slug,
    params.slugTask,
  ]);
};

export default useDocumentTitle;
