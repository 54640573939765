export const STATUS_NEW = 101;
export const STATUS_DRAFT = 102;
export const STATUS_DISCUSS = 201;
export const STATUS_IN_WORK = 301;

export const projectTansactionTypes = {
  0: 'base',
  1: 'success',
  120: 'base',
  130: 'success',
};

export const balanceTansactionTypes = {
  0: 'base',
  1: 'success',
  2: 'default',
  3: 'warning',
  100: 'warning',
  1000: 'success',
};

export const bonusesTansactionTypes = {
  0: 'warning',
  1: 'success',
  2: 'default',
  100: 'default',
  1000: 'success',
};

export const STAGES_DATA: {
  [key: string]: string;
} = {
  new: 'Нові завдання',
  pending: 'Обговорення',
  inProcess: 'У роботі',
  checking: 'Перевірка',
  approval: 'Затверждення у клієнта',
};

export const DATE_FORMAT = 'MM.DD.YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_SHORT = 'HH:mm';
export const DATE_FORMAT_FULL = 'YYYY.MM.DD HH:mm:ss';

export const generalChatType = 1;
export const techChatType = 2;
export const managerChatType = 3;

export const TIME_VARIANTS = {
  seconds: ['секунда', 'секунди', 'секунд'],
  minutes: ['хвилина', 'хвилини', 'хвилин'],
  hours: ['година', 'години', 'годин'],
};

export const PRIORITY_COLORS = {
  1: '#F0B139',
  2: '#E4862B',
  3: '#E6792E',
  4: '#EA6333',
};

export const PROJECT_ROLES = {
  productOwner: 4,
  productModerator: 5,
  productMember: 6,
};

// task statuses
export const TASK_STATUS_NEW = 101;

export const PROJECT_ROLES_COLORS: {
  [key: number]: string;
} = {
  4: '#F0B139',
  5: '#2DD57E',
  6: '#1FABF0',
  7: '#FF2C2C',
  8: '#ff7070',
};

export const GLOBAL_ROLES_COLORS: {
  [key: number]: string;
} = {
  // 2: '#2DD57E',
  3: '#1FABF0',
  4: '#A87AFB',
};

export const defaultChartData = [
  {
    project: 0,
    tips: 0,
    other: 0,
    month: 2,
  },
  { project: 0, tips: 0, other: 0, month: 3 },
  {
    project: 0,
    tips: 0,
    other: 0,
    month: 4,
  },
  {
    project: 0,
    tips: 0,
    other: 0,
    month: 5,
  },
  { project: 0, tips: 0, other: 0, month: 6 },
  {
    project: 0,
    tips: 0,
    other: 0,
    month: 7,
  },
  {
    project: 0,
    tips: 0,
    other: 0,
    month: 8,
  },
  { project: 0, tips: 0, other: 0, month: 9 },
  {
    project: 0,
    tips: 0,
    other: 0,
    month: 10,
  },
];

export const isLocalhost = (): boolean =>
  Boolean(process.env.REACT_APP_BASE_URL?.includes('localhost'));

export const SITE_TITLE = 'Negayno!';
