import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, set } from 'react-hook-form';
import dayjs from 'dayjs';

import {
  changePriorityCO,
  coActions,
  getTaskData,
  addAssessment,
  deleteAssessment,
  updateAssessment,
  finishAssessment,
  continueAssessment,
  checkAssessment,
} from '../../store/tasks/thunk';

import Heading from '../UI/Heading/Heading';
import Button from '../Button/Button';
import Range from '../UI/Range/Range';

import { ReactComponent as IconMan } from '../../media/icons/man.svg';
import { ReactComponent as IconLogo } from '../../logo.svg';
import { ReactComponent as CheckLogo } from '../../media/icons/check.svg';

import { AppDispatch } from '../../store';
import { commercialOfferTextStatuses } from '../../helpers/constant';

import './CommercialOffer.scss';
import Modal from '../Modal/Modal';
import { PROJECT_ROLES } from '../../common/constant';
import { getProjectData } from '../../store/projects/thunk';
import Input from '../UI/Input/Input';

interface ICommercialOfferProps {
  group: any;
  taskId: string;
  slug: string;
  priority: number;
  rangeClickBlocked: boolean;
  setRangeClickBlocked: (value: boolean) => void;
}

// made interface for commercialoffercheck without two seconds values in icommertialofferprops
interface ICommercialOfferCheckProps {
  group: any;
  slug: string;
  taskId: string;
}

const COfferActions = {
  rejectStart: 'Відхилити запит на старт робіт по КП',
  reoffer: 'Відправити запит на оновлення КП',
  rejectTest: 'Відхилити запит перевірки робіт по КП на тесті',
  successTest: 'Прйняти роботи по КП на тесті',
  rejectProd: 'Відхилити запит перевірки робіт по КП на проді',
  successProd: 'Прйняти роботи по КП на проді',
};

const CommercialOffer: React.FC<ICommercialOfferProps> = ({
  group,
  taskId,
  slug,
  priority,
  rangeClickBlocked,
  setRangeClickBlocked,
}) => {
  const {
    calculations,
    name,
    total,
    totalOld,
    dateStart,
    deadline,
    deadlineOld,
  } = group;

  const dispatch: AppDispatch = useDispatch();
  const { taskInfo } = useSelector((state: any) => state.taskData);

  const [isRejected, setIsRejected] = useState(false);
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.userData);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<any>();
  const newCOPriority = useRef<number>(
    group?.priority ? group.priority : priority
  );
  // const [newCOPriority, setNewCOPriority] = useState<number>(
  //   group?.priority ? group.priority : priority
  // );

  useEffect(() => {
    newCOPriority.current = group?.priority ? group.priority : priority;
    // setNewCOPriority(group?.priority ? group.priority : priority);
  }, [priority]);

  useEffect(() => {
    reset({
      data: 'comment',
    });
  }, [isRejected]);

  const handleCOfferDelete = (): void => {
    if (group.textStatus === commercialOfferTextStatuses.checkTest) {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'rejectTest',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        dispatch(getTaskData(taskInfo.slug)).then(() => {
          dispatch(getProjectData(taskInfo?.project.slug));
          reset({
            data: 'comment',
          });
        });
        setIsRejected(false);
      });
    } else if (group.textStatus === commercialOfferTextStatuses.checkProd) {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'rejectProd',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        dispatch(getTaskData(taskInfo.slug)).then(() => {
          reset({
            data: 'comment',
          });
        });
        // setIsRejected(false);
      });
    } else if (group.reoffer === 1) {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'delete',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        reset({
          data: 'comment',
        });
        // setIsRejected(false);
      });
    } else {
      dispatch(
        coActions({
          slug,
          value: getValues('comment'),
          action: 'rejectStart',
          groupId: group.id,
          param: 'text',
        })
      ).then(() => {
        reset({
          data: 'comment',
        });
        // setIsRejected(false);
      });
    }
  };

  const handleSendToWork = (): void => {
    dispatch(
      coActions({
        slug,
        action: 'confirmStart',
        groupId: group.id,
      })
    );
  };

  const handleReoffer = (): void => {
    dispatch(
      coActions({
        slug,
        action: 'reoffer',
        groupId: group.id,
      })
    ).then(() => {
      dispatch(getTaskData(taskInfo.slug));
      navigate(`/task/${taskInfo.slug}/`);
    });
  };

  const handleChangeCOPriority = useCallback(
    async (value: number): Promise<any> => {
      if (rangeClickBlocked) return;
      setRangeClickBlocked(true);
      newCOPriority.current = value;
      // setNewCOPriority(value);
      // dispatch(
      //   changePriorityCO({
      //     slug,
      //     groupId: group.id,
      //     priority: value,
      //   })
      // )
      //   .unwrap()
      //   .then(() => {
      //     console.log('Priority changed');
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     newCOPriority.current = group.priority;
      //     // setNewCOPriority(group.priority);
      //   })
      //   .finally(() => {
      //     setRangeClickBlocked(false);
      //   });
      // rewrite to async/await

      try {
        await dispatch(
          changePriorityCO({
            slug,
            groupId: group.id,
            priority: value,
          })
        );
      } catch (error) {
        newCOPriority.current = group.priority;
      } finally {
        setRangeClickBlocked(false);
      }
    },
    [rangeClickBlocked, group.id]
  );

  return (
    <>
      {/* {group.textStatus === commercialOfferTextStatuses.confirmStart && (
        <Button
          onClick={() => {
            dispatch(
              coActions({
                slug,
                groupId: group.id,
                action: 'checkTest',
              })
            ).then(() => {
              dispatch(getProjectData(taskInfo?.project.slug));
              navigate(`/task/${taskInfo.slug}`);
            });
          }}
        >
          check test
        </Button>
      )} */}
      {/* {group.textStatus === commercialOfferTextStatuses.successTest && (
        <Button
          onClick={() => {
            dispatch(
              coActions({
                slug,
                groupId: group.id,
                action: 'checkProd',
              })
            ).then(() => {
              dispatch(getProjectData(taskInfo?.project.slug));
              dispatch(getTaskData(taskInfo.slug));
              navigate(`/task/${taskInfo.slug}`);
            });
          }}
        >
          check prod
        </Button>
      )} */}
      {group.status >= 6 &&
        group.textStaus !== commercialOfferTextStatuses.reoffer &&
        !group?.reoffer && (
          <CommercialOfferCheck
            priority={priority}
            group={group}
            slug={slug}
            taskId={taskId}
          />
        )}
      {group.textStatus !== commercialOfferTextStatuses.confirmStart &&
        group.textStatus !== commercialOfferTextStatuses.successTest &&
        group.textStatus !== commercialOfferTextStatuses.archive && (
          <div className="commercial-offer">
            <div className="commercial-offer__header">
              {name}
              <IconMan className="commercial-offer__man" />
            </div>
            <div className="commercial-offer__body">
              {calculations.map((calculation: any) => (
                <div
                  key={Date.now() * Math.random()}
                  className="commercial-offer__item"
                >
                  {calculation.text}
                </div>
              ))}
              {group.textStatus !== commercialOfferTextStatuses.reoffer &&
                group.textStatus !== commercialOfferTextStatuses.checkTest &&
                group.textStatus !== commercialOfferTextStatuses.checkProd &&
                !group.reoffer && (
                  <>
                    {dateStart && (
                      <div className="commercial-offer__item commercial-offer__item--short">
                        <div className="commercial-offer__costs">
                          <div className="commercial-offer__costs-left">
                            Дата початку:
                          </div>
                          <div className="commercial-offer__costs-right">
                            <div className="commercial-offer__cost-sale">
                              {dayjs.unix(dateStart).format('DD.MM.YYYY')}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {dateStart && (
                      <div className="commercial-offer__item commercial-offer__item--short">
                        <div className="commercial-offer__costs">
                          <div className="commercial-offer__costs-left">
                            Дата завершення:
                          </div>
                          <div className="commercial-offer__costs-right">
                            {deadlineOld &&
                              userInfo.roleId ===
                                PROJECT_ROLES.productOwner && (
                                <div className="commercial-offer__cost-old">
                                  {dayjs.unix(deadlineOld).format('DD.MM.YYYY')}
                                </div>
                              )}
                            <div className="commercial-offer__cost-sale">
                              {dayjs.unix(deadline).format('DD.MM.YYYY')}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!!total &&
                      userInfo.roleId === PROJECT_ROLES.productOwner && (
                        <div className="commercial-offer__item commercial-offer__item--short">
                          <div className="commercial-offer__costs">
                            <div className="commercial-offer__costs-left">
                              Вартість:
                            </div>
                            <div className="commercial-offer__costs-right">
                              {totalOld && (
                                <div className="commercial-offer__cost-old">
                                  {totalOld}
                                </div>
                              )}
                              <div className="commercial-offer__cost-sale">
                                {total !== 0
                                  ? `${group.total} грн`
                                  : 'За домовленістю'}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
              {!isRejected &&
                userInfo.roleId === PROJECT_ROLES.productOwner &&
                !!group.total &&
                group.textStatus !== commercialOfferTextStatuses.checkProd &&
                group.textStatus !== commercialOfferTextStatuses.checkTest &&
                group.textStatus !== commercialOfferTextStatuses.reoffer &&
                !group.reoffer && (
                  <>
                    <Heading className="commercial-offer__title" size="sm">
                      Швидкість та дедлайн
                    </Heading>
                    <Range
                      key={`${newCOPriority.current}${userInfo.roleId}`}
                      // key={`${newCOPriority}${userInfo.roleId}`}
                      disabled={rangeClickBlocked}
                      // initialValue={newCOPriority}
                      initialValue={newCOPriority.current}
                      className="commercial-offer__range"
                      contentClassName="commercial-offer__range-content"
                      textClassName="commercial-offer__range-text"
                      activeTextClassName="commercial-offer__range-text--active"
                      inputClassName="commercial-offer__range-input"
                      dots={false}
                      onMouseUp={handleChangeCOPriority}
                    />
                  </>
                )}

              {isRejected && (
                <>
                  <div
                    className="commercial-offer__item commercial-offer__item--textarea-wrapper"
                    style={{
                      borderColor: errors.comment && '#FF0000',
                    }}
                  >
                    <textarea
                      className="commercial-offer__input"
                      placeholder="Введіть повідомлення"
                      autoFocus
                      {...register('comment', { required: true })}
                    />
                  </div>
                  <div className="commercial-offer__item commercial-offer__item--transparent">
                    <Button
                      onClick={() => setIsRejected(false)}
                      className="commercial-offer__button"
                    >
                      Закрити
                    </Button>

                    <Button
                      onClick={handleSubmit(handleCOfferDelete)}
                      className="commercial-offer__button commercial-offer__button--danger"
                    >
                      Відправити
                    </Button>
                  </div>
                </>
              )}
            </div>
            {!isRejected &&
              (!group.parentId || (group.parentId && group.canConfirm)) && (
                <div className="commercial-offer__footer">
                  <div className="commercial-offer__buttons">
                    {group.textStatus ===
                      commercialOfferTextStatuses.checkStart &&
                      group.reoffer !== 1 &&
                      userInfo.roleId === PROJECT_ROLES.productOwner && (
                        <Button
                          onClick={() => {
                            setIsRejected(true);
                          }}
                          className="commercial-offer__button "
                        >
                          Відхилити
                        </Button>
                      )}
                    {userInfo.roleId === PROJECT_ROLES.productOwner &&
                      group.textStatus !==
                        commercialOfferTextStatuses.checkTest &&
                      group.reoffer !== 1 &&
                      group.textStatus !==
                        commercialOfferTextStatuses.checkProd &&
                      (group.textStatus ===
                      commercialOfferTextStatuses.reoffer ? (
                        <div
                          style={{ cursor: 'default' }}
                          className="commercial-offer__item commercial-offer__item--short"
                        >
                          Запрошено оновлення КП
                        </div>
                      ) : group.textStatus ===
                        commercialOfferTextStatuses.reoffer ? (
                        <Button
                          onClick={handleReoffer}
                          className="commercial-offer__button commercial-offer__button--success"
                        >
                          Запросити оновлення КП
                        </Button>
                      ) : (
                        <Button
                          onClick={handleSendToWork}
                          className="commercial-offer__button commercial-offer__button--success"
                        >
                          Відправити в роботу
                        </Button>
                      ))}
                    {group.reoffer === 1 && (
                      <>
                        <Button
                          onClick={() => setIsRejected(true)}
                          className="commercial-offer__button"
                        >
                          Відправити в архів
                        </Button>
                        <Button
                          onClick={handleReoffer}
                          className="commercial-offer__button commercial-offer__button--success"
                        >
                          Запросити оновлення КП
                        </Button>
                      </>
                    )}
                    {!group.parentId || (group.parentId && group.canConfirm)
                      ? group.textStatus !==
                          commercialOfferTextStatuses.reoffer &&
                        group.textStatus !==
                          commercialOfferTextStatuses.checkStart &&
                        !group.reoffer &&
                        (group.textStatus ===
                        commercialOfferTextStatuses.checkTest ? (
                          <Button
                            onClick={() => {
                              setIsRejected(true);
                            }}
                            className="commercial-offer__button "
                          >
                            Відхилити
                          </Button>
                        ) : group.textStatus ===
                          commercialOfferTextStatuses.checkProd ? (
                          <Button
                            onClick={() => {
                              setIsRejected(true);
                            }}
                            className="commercial-offer__button "
                          >
                            Знайдено помилки
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setIsRejected(true);
                            }}
                            className="commercial-offer__button "
                          >
                            Відхилити з коментарем
                          </Button>
                        ))
                      : null}
                    {/* {userInfo.roleId === PROJECT_ROLES.productOwner &&
                    group.textStatus === commercialOfferTextStatuses.reoffer && (
                      <div
                        style={{ cursor: 'default' }}
                        className="commercial-offer__item commercial-offer__item--short"
                      >
                        Запрошено оновлення КП 1
                      </div>
                    )} */}
                    {group.textStatus ===
                      commercialOfferTextStatuses.checkTest &&
                    (!group.parentId ||
                      (group.parentId && group.canConfirm)) ? (
                      <Button
                        onClick={() => {
                          dispatch(
                            coActions({
                              slug,
                              groupId: group.id,
                              action: 'successTest',
                            })
                          );
                        }}
                        className="commercial-offer__button commercial-offer__button--success"
                      >
                        Прийняти роботи на тесті
                      </Button>
                    ) : userInfo.roleId === PROJECT_ROLES.productOwner &&
                      group.textStatus ===
                        commercialOfferTextStatuses.checkProd &&
                      (!group.parentId ||
                        (group.parentId && group.canConfirm)) ? (
                      <Button
                        onClick={() => {
                          dispatch(
                            coActions({
                              slug,
                              groupId: group.id,
                              action: 'successProd',
                            })
                          );
                        }}
                        className="commercial-offer__button commercial-offer__button--success"
                      >
                        Все добре, дякую.Роботу прийнято
                      </Button>
                    ) : null}
                  </div>
                </div>
              )}
          </div>
        )}
    </>
  );
};

export default CommercialOffer;

interface ICommercialOfferCheckProps
  extends Omit<
    ICommercialOfferProps,
    'rangeClickBlocked' | 'setRangeClickBlocked'
  > {
  priority: number;
}

export const CommercialOfferCheck: React.FC<ICommercialOfferCheckProps> = ({
  group,
  slug,
  priority,
}) => {
  const [isWorkDetails, setIsWorkDetails] = useState<boolean>(false);
  const { taskInfo } = useSelector((state: any) => state.taskData);
  const { userInfo } = useSelector((state: any) => state.userData);

  const showWorkDetailsHandler = (): void => {
    setIsWorkDetails((prev) => !prev);
  };

  return (
    <div className="commercial-offer-check">
      <Modal
        size="md"
        title="Деталі виконаних робіт"
        isActive={isWorkDetails}
        handleClose={showWorkDetailsHandler}
        leftButton={false}
        modalClassName="commercial-offer-check__modal-inner"
        bodyClassName="commercial-offer-check__modal-body quill"
      >
        <div className="commercial-offer-check__item-ttl commercial-offer-check__item-ttl--md">
          {group.name}
        </div>
        <div className="commercial-offer-check__content ql-editor">
          <ol className="">
            {group.calculations.map((calculation: any) => (
              <li
                key={Date.now() * Math.random()}
                className="commercial-offer-check__item-text"
              >
                {calculation.text}
              </li>
            ))}
          </ol>
        </div>
      </Modal>

      <div className="commercial-offer-check__header">
        <IconLogo className="commercial-offer-check__logo" />
        <div className="commercial-offer-check__title">{group.name}</div>
      </div>
      <div className="commercial-offer-check__body">
        <div className="commercial-offer-check__item">
          Статус:{' '}
          <Button className="commercial-offer-check__button commercial-offer__button commercial-offer__button--success">
            Підтверджено
          </Button>
        </div>
        <div className="commercial-offer-check__item">
          Технічне завдання:{' '}
          <span
            className="commercial-offer-check__show-details"
            onClick={showWorkDetailsHandler}
          >
            дивитись
          </span>
        </div>
        <div className="commercial-offer-check__item">
          Старт робіт:{' '}
          <span>{dayjs.unix(group.dateStart).format('DD.MM.YYYY')}</span>
        </div>
        <div className="commercial-offer-check__item">
          Дедлайн:{' '}
          <span>{dayjs.unix(group.deadline).format('DD.MM.YYYY')}</span>
        </div>
      </div>
      {userInfo.roleId === PROJECT_ROLES.productOwner && (
        <div className="commercial-offer-check__footer">
          <div className="commercial-offer-check__item">
            Вартість робіт:{' '}
            <div className="commercial-offer-check__total">
              {group.total !== 0 ? `${group.total} грн` : 'За домовленістю'}
            </div>
          </div>
        </div>
      )}
      {/* {userInfo.roleId === PROJECT_ROLES.productOwner && (
        <ButtonByGroupStatusCancel
          group={{ status: 5 }}
          onClick={() => console.log()}
        />
      )}
      {userInfo.roleId === PROJECT_ROLES.productOwner && (
        <ButtonByGroupStatus
          group={{ status: 5 }}
          onClick={() => console.log()}
        />
      )} */}
    </div>
  );
};

const ButtonByGroupStatus: React.FC<{
  group: any;
  onClick: () => void;
}> = ({ group, onClick }) => {
  const CurrentButton = useMemo(() => {
    switch (group.status) {
      case 1:
        return (
          <Button
            onClick={onClick}
            className="commercial-offer__button commercial-offer__button--success"
          >
            Відправити в роботу
          </Button>
        );
      case 2:
        return (
          <Button
            onClick={onClick}
            className="commercial-offer__button commercial-offer__button--success"
          >
            Прийняти роботи на тесті
          </Button>
        );
      case 3:
        return (
          <Button
            onClick={onClick}
            className="commercial-offer__button commercial-offer__button--success"
          >
            Все добре, дякую.Роботу прийнято
          </Button>
        );
      default:
        return null;
    }
  }, [group.status]);

  return CurrentButton;
};

const ButtonByGroupStatusCancel: React.FC<{
  group: any;
  onClick: () => void;
}> = ({ group, onClick }) => {
  const CurrentButton = useMemo(() => {
    switch (group.status) {
      case 1:
        return (
          <Button onClick={onClick} className="commercial-offer__button ">
            Відхилити
          </Button>
        );

      case 2:
        return (
          <Button onClick={onClick} className="commercial-offer__button ">
            Знайдено помилки
          </Button>
        );

      case 3:
        return (
          <Button onClick={onClick} className="commercial-offer__button ">
            Відхилити з коментарем
          </Button>
        );

      default:
        return null;
    }
  }, [group.status]);

  return CurrentButton;
};

export const TaskAssessment = ({ task, isMobile }: any): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const [isAddAssessment, setIsAddAssessment] = useState(
    Array.from({ length: task?.groups?.length }, () => false)
  );
  const [days, setDays] = useState('0');

  const name = task?.name;

  const isNotArchive = task.textStatus !== 'archive';
  const confirmClient = task.textStatus === 'confirmClient';
  const isNew = task.textStatus === 'new';
  // const isNew = true;

  const changeAddAssessment = (groupIndex: any): void => {
    setIsAddAssessment((prev) =>
      prev.map((item, index) => (index === groupIndex ? !item : false))
    );
  };

  const updateAssessmentHandler = (groupId: number): void => {
    dispatch(
      updateAssessment({
        slug: task.slug,
        groupId,
        value: days,
      })
    );
  };

  const deleteAssessmentHandler = (id: number, groupId: number): void => {
    dispatch(
      deleteAssessment({
        slug: task.slug,
        action: 'rejectDeveloper',
        groupId,
        calcId: id,
      })
    );
  };

  const finishAssessmentHandler = (groupId: number): void => {
    dispatch(
      finishAssessment({
        groupId,
        slug: task.slug,
      })
    );
  };

  const continueAssessmentHandler = (groupId: number): void => {
    dispatch(
      continueAssessment({
        groupId,
        slug: task.slug,
      })
    );
  };

  // useEffect(() => {
  //   const scrollBlock = document.querySelector('.task__body');
  //   if (!scrollBlock) return;
  //   const scrollHeight = scrollBlock?.scrollHeight;
  //   if (scrollHeight > scrollBlock.clientHeight && !isMobile) {
  //     if (isAddAssessment) {
  //       scrollBlock.scrollTo({
  //         top: scrollBlock.scrollHeight,
  //         behavior: 'smooth',
  //       });
  //     } else {
  //       scrollBlock.scrollTo({
  //         top: 0,
  //         behavior: 'smooth',
  //       });
  //     }
  //   }
  // }, [isAddAssessment]);

  return (
    <div className="task-assessment">
      {task?.groups.map((group: any, groupIndex: any) => {
        const isAnyActualAssessment = group.calculations.some(
          (calculation: any) => !calculation.status
        );
        return (
          <div key={group.id} className="task-assessment__content">
            <div className="task-assessment__title">{name}</div>
            {!!group?.wait && isAnyActualAssessment && (
              <div className="task-assessment__sub-title">
                <h4>термін виконання днів {group.term > 0 && group.term}</h4>
                {/* {!group.term && isNotArchive && ( */}
                {group?.textStatus === 'new' && group.userStatus === 1 && (
                  <div>
                    <span className="task-assessment__label">
                      Час на виконання завдання, год
                    </span>
                    <Input
                      defaultValue={group?.term || days}
                      min={1}
                      type="number"
                      onChange={(e) => setDays(e.target.value)}
                    />
                    {+days > 0 && (
                      <Button
                        view="default"
                        onClick={() => {
                          updateAssessmentHandler(group.id);
                          setDays('');
                        }}
                        className="task-assessment__btn"
                      >
                        Ок
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
            {!!group?.wait && (
              <div className="task-assessment__table-wrapper">
                <table className="task-assessment__table">
                  <tr>
                    <th className="task-assessment__table-heading">
                      Опис робіт
                    </th>
                    <th className="task-assessment__table-heading">
                      Час, год{' '}
                    </th>
                    <th className="task-assessment__table-heading">
                      Вартість{' '}
                    </th>
                  </tr>
                  {group?.calculations &&
                    group?.calculations?.map((calculation: any) => {
                      const fields = ['text', 'hour', 'price'];
                      return (
                        <tr key={calculation.id}>
                          {fields.map((field) => (
                            <Strike
                              key={field}
                              status={calculation.status}
                              tag="td"
                            >
                              {calculation[field]}
                            </Strike>
                          ))}
                          {!calculation.status &&
                            group?.textStatus === 'new' && (
                              <td>
                                <Button
                                  view="warning"
                                  className="task-assessment__delete-btn"
                                  onClick={() =>
                                    deleteAssessmentHandler(
                                      calculation.id,
                                      group.id
                                    )
                                  }
                                >
                                  <span className="ci ci-trash_full" />
                                </Button>
                              </td>
                            )}
                          {calculation.status > 2 && confirmClient && (
                            <td>
                              <Button
                                view="success"
                                className="task-assessment__check-btn"
                                onClick={() => {
                                  dispatch(
                                    checkAssessment({
                                      slug: task.slug,
                                      groupId: group.id,
                                    })
                                  );
                                }}
                              >
                                <CheckLogo />
                              </Button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  <tr>
                    <td className="task-assessment__text-right task-assessment__text--small">
                      Загалом по КП:
                    </td>
                    <td>{group.totalHour}</td>
                    <td>{task.total ? task.total : 0} грн</td>
                  </tr>
                  <tr>
                    <td className="task-assessment__text-right task-assessment__text--small">
                      Старт робіт:{' '}
                    </td>
                    <td>{dayjs.unix(group.dateStart).format('DD.MM.YYYY')}</td>
                  </tr>
                  <tr>
                    <td className="task-assessment__text-right task-assessment__text--small">
                      Дедлайн:{' '}
                    </td>
                    <td className="task-assessment__text--small">
                      {group.deadline && (
                        <div>
                          {dayjs.unix(group.deadline).format('DD.MM.YYYY')}
                        </div>
                      )}
                      {group.deadlineOld && (
                        <Strike status={1}>
                          {dayjs.unix(group.deadlineOld).format('DD.MM.YYYY')}
                        </Strike>
                      )}
                    </td>
                  </tr>
                </table>
              </div>
            )}

            {!!group?.wait && (
              <>
                {isAddAssessment[groupIndex] && group?.textStatus === 'new' ? (
                  <AddAssessment
                    task={task}
                    groupId={group.id}
                    changeAssessment={() => changeAddAssessment(groupIndex)}
                  />
                ) : (
                  <div className="task-assessment__btns">
                    {group?.textStatus === 'new' && group.userStatus === 1 ? (
                      <>
                        <Button
                          view="default"
                          onClick={() => changeAddAssessment(groupIndex)}
                          className="task-assessment__btn"
                        >
                          Додати оцінку
                        </Button>
                        {+group.term > 0 && isAnyActualAssessment && (
                          <Button
                            view="success"
                            onClick={() => finishAssessmentHandler(group.id)}
                            className="task-assessment__btn task-assessment__btn--color-white"
                          >
                            Завершити оцінювання
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        view="third"
                        className="task-assessment__btn task-assessment__btn--finish"
                        onClick={() => continueAssessmentHandler(group.id)}
                      >
                        Продовжити оцінювання
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const Strike = ({ children, status, tag = 'div' }: any): JSX.Element => {
  const TagName = tag;
  return (
    <TagName className={status === 1 ? 'task-assessment__through' : ''}>
      {children}
    </TagName>
  );
};

const AddAssessment = ({
  task,
  changeAssessment,
  groupId,
}: any): JSX.Element => {
  const [assessment, setAssessment] = useState({
    text: '',
    hour: '',
  });
  const [errors, setErrors] = useState({
    text: false,
    hour: false,
  });
  const dispatch: AppDispatch = useDispatch();

  const addAssessmentHandler = (): void => {
    dispatch(
      addAssessment({
        slug: task.slug,
        action: 'add',
        hour: assessment.hour,
        text: assessment.text,
        groupId,
      })
    )
      .then(() => {
        dispatch(getTaskData(task.slug));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeAssessmentHandler = (e: any, type: string): void => {
    setAssessment({ ...assessment, [type]: e.target.value });
  };

  const sendAssessmentHandler = (): void => {
    if (!assessment.text.trim().length) {
      setErrors((prev) => ({ ...prev, text: true }));
    } else if (assessment.text.length) {
      setErrors((prev) => ({ ...prev, text: false }));
    }
    if (+assessment.hour < 1) {
      setErrors((prev) => ({ ...prev, hour: true }));
    } else if (+assessment.hour > 0) {
      setErrors((prev) => ({ ...prev, hour: false }));
    }
    if (assessment.text.length && +assessment.hour > 0) {
      addAssessmentHandler();
      changeAssessment();
    }
  };

  return (
    <div>
      <div className="task-assessment__item">Нова оцінка</div>
      <form action="javascript:void(0);" className="task-assessment__item">
        <span className="task-assessment__label">Короткий опис робіт</span>
        <textarea
          autoFocus
          className="task-assessment__input"
          onChange={(e) => changeAssessmentHandler(e, 'text')}
        />
        {errors.text && (
          <div className="task-assessment__error task-assessment__error--textarea">
            Введіть опис робіт
          </div>
        )}
        <span className="task-assessment__label">
          Час на виконання завдання, год
        </span>
        <Input
          type="number"
          onChange={(e) => changeAssessmentHandler(e, 'hour')}
          min={1}
        >
          {errors.hour && (
            <div className="task-assessment__error">
              Введіть кількість годин
            </div>
          )}
        </Input>
        <div className="task-assessment__btns">
          <Button
            onClick={() => {
              changeAssessment();
            }}
          >
            Закрити
          </Button>
          <Button view="default" onClick={sendAssessmentHandler} type="submit">
            Зберегти
          </Button>
        </div>
      </form>
    </div>
  );
};
