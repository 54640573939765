import React, { memo, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Heading from '../UI/Heading/Heading';
import Text from '../UI/Text/Text';
import Button from '../Button/Button';

import { getChartdataArrays } from '../../helpers/common';
import { months } from '../../helpers/constant';
import { TChartLabel } from '../../common/types';
import { useThemeToggler } from '../../context/ThemeContext';

import { defaultChartData } from '../../common/constant';

import { ReactComponent as DotsGroup } from '../../media/icons/dots-group.svg';

import './BalanceOperations.scss';

interface IBalanceOperationsProps {
  className?: string;
  projectId?: number;
  balance?: any;
  transactions?: any;
  onRefill?: () => void;
  statistic?: any;
  chartLabels?: any;
  isBonuses?: boolean;
  buttonTitle?: string;
  onShowMore?: () => void;
  transactionTypes?: any;
  isLast?: boolean;
  showButton?: boolean;
}

const BalanceOperations: React.FC<IBalanceOperationsProps> = ({
  balance,
  transactions,
  onRefill,
  chartLabels,
  statistic,
  onShowMore,
  transactionTypes,
  isLast = false,
  buttonTitle = 'Поповнити баланс',
  showButton = true,
}) => {
  const { theme } = useThemeToggler();
  const chart = useMemo(
    () => (!statistic?.length ? defaultChartData : statistic),
    [statistic]
  );

  return (
    <div className="balance-operations">
      <div className="balance-operations__top">
        <div className="balance-operations__left">
          <div
            className={cx('balance-operations__total block-total', {
              [`block-total--${theme}`]: theme,
            })}
          >
            <Heading className="block-total__title" size="sm">
              Загальний баланс
            </Heading>
            <Text className="block-total__value">
              {balance ? balance : 0} грн
            </Text>
            {showButton && (
              <Button
                onClick={onRefill}
                view="success"
                className="balance-operations__button"
              >
                {buttonTitle}
              </Button>
            )}
          </div>
        </div>
        <div className="balance-operations__right">
          <div className="balance-operations__chart">
            {chart && <CustomChart chartLabels={chartLabels} chart={chart} />}
          </div>
        </div>
      </div>
      <div className="balance-operations__bottom">
        {transactions && (
          <OperationsTable
            isLast={isLast}
            transactionTypes={transactionTypes}
            onShowMore={onShowMore}
            operations={transactions?.transactionList}
          />
        )}
      </div>
    </div>
  );
};

export default BalanceOperations;

const OperationsTable = ({
  operations,
  onShowMore,
  isLast,
  transactionTypes,
}: any): JSX.Element | null => {
  const { theme } = useThemeToggler();
  const navigate = useNavigate();

  if (operations && !operations.length) return null;

  return (
    <div
      className={cx('balance-operations__table operations-table', {
        [`operations-table--${theme}`]: theme,
      })}
    >
      <div className="operations-table__row operations-table__row--head">
        <div className="operations-table__cell operations-table__cell--date">
          Дата і час
        </div>
        <div className="operations-table__cell operations-table__cell--type">
          {operations?.some((item: any) => item?.transactionNumber) && (
            <>Номер танзакції</>
          )}
        </div>
        <div className="operations-table__cell operations-table__cell--action">
          Подія
        </div>
        <div className="operations-table__cell operations-table__cell--sum-change">
          Зміна баланса
        </div>
        <div className="operations-table__cell operations-table__cell--sum-before">
          Баланс до події
        </div>
        <div className="operations-table__cell operations-table__cell--sum-after">
          Баланс після події
        </div>
      </div>
      <div className="operations-table__body">
        {operations?.map((operation: any) => (
          <BalanceOperationRow
            key={operation.id + Math.random().toString(36).substring(7)}
            theme={theme}
            operation={operation}
            transactionTypes={transactionTypes}
          />
        ))}
      </div>
      {onShowMore && isLast && (
        <div className="operations-table__footer">
          <Button
            className="operations-table__show-more"
            onClick={onShowMore}
            view="text"
          >
            <DotsGroup />
            Показати щє
          </Button>
        </div>
      )}
    </div>
  );
};

let ChartistGraph: any = null;

const getMaxValueFromChart = (arr: any): number => {
  let max = 0;
  arr.forEach((item: any) => {
    let mMax = 0;
    Object.keys(item).forEach((key: any) => {
      if (key === 'month') return;
      mMax += item[key];
      if (mMax > max) max = mMax;
    });
  });
  return max;
};

export const CustomChart = memo(
  ({
    chart,
    chartLabels,
    chartHeight = 164,
    type = 'Bar',
  }: any): JSX.Element | null => {
    const { theme } = useThemeToggler();
    const isTablet = useMediaQuery({ query: '(max-width: 1200px)' });

    useEffect(() => {
      import('react-chartist').then((module) => {
        ChartistGraph = module.default;
        setIsChartistLoaded(true);
      });
      return () => {
        ChartistGraph = null;
      };
    }, []);

    const monthsSorted = useMemo(
      () =>
        Object.entries(months)
          .slice(1, chart.length + 1)
          .sort((a: any, b: any) => {
            if (a[0].length < b[0].length) {
              return b[0] - a[0];
            } else {
              return a[0] - b[0];
            }
          })
          .map((item: any) => item[1]),
      [chart]
    );

    const data = useMemo(
      () => ({
        labels: !isTablet
          ? monthsSorted
          : monthsSorted.map((item) => `${item.slice(0, 3)}.`),
        datasets: [
          {
            backgroundColor: 'rgba(40, 139, 170, 1)',
            borderWidth: 10,
            borderSkipped: false,
          },
        ],
        series: chart.length ? [...getChartdataArrays(chart.slice(0))] : [],
      }),
      [chart, isTablet]
    );

    const options = useMemo(
      () => ({
        high: getMaxValueFromChart(chart) ? getMaxValueFromChart(chart) : 10000,
        low: 0,
        seriesBarDistance: 0,
        axisX: {
          scaleMinSpace: 10,
          labelInterpolationFnc: (value: any, index: any) =>
            index % 1 === 0 ? value : null,
        },
        axisY: {
          offset: isTablet ? 50 : 80,
          scaleMinSpace: !chart.length ? 12 : 20,
        },
        chartPadding: {
          top: 10,
          right: 16,
          bottom: 0,
          left: 0,
        },
        stackBars: true,
        width: '100%',
        height: '180px',
        fullWidth: true,
        showArea: true,
        areaBase: 100,
      }),
      [chart, chartLabels, chartHeight, isTablet]
    );

    const [isChartistLoaded, setIsChartistLoaded] = useState(false);

    useEffect(() => {
      if (isChartistLoaded) {
        const chartBlock = document.querySelector('.ct-chart');
        const chartRows = chartBlock!.querySelectorAll('.ct-series');
        chartRows.forEach((item: any, index: number) => {
          const lines = item.querySelectorAll('.ct-bar');
          lines.forEach((line: any) => {
            if (chartLabels[index]?.color) {
              line.setAttribute(
                'style',
                `stroke: ${chartLabels[index].color} !important`
              );
              line.style.stroke = chartLabels[index].color;
            }
          });
        });
      }
    }, [chart, chartLabels, isChartistLoaded]);

    if (!isChartistLoaded) return null;

    return (
      <div
        className={cx('custom-chart', {
          [`custom-chart--${theme}`]: theme,
        })}
      >
        <div className="custom-chart__desc">
          {chartLabels?.length &&
            chartLabels.map(({ label, color }: TChartLabel) => (
              <div
                key={label + Math.random().toString(36).substring(7)}
                className="custom-chart__item"
                style={
                  {
                    '--color': color,
                  } as React.CSSProperties
                }
              >
                <div className="custom-chart__icon custom-chart__icon--1" />
                {label}
              </div>
            ))}
        </div>
        <div className="chart-wrapper">
          {!isTablet && (
            <svg
              width="160px"
              height="100%"
              className="custom-chart__fake-svg-line"
            >
              <line
                x1="154.3"
                x2="154.3"
                y1="10"
                y2="152"
                className="ct-grid ct-horizontal"
              />
            </svg>
          )}
          {type && <ChartistGraph data={data} options={options} type={type} />}
        </div>
      </div>
    );
  }
);

const BalanceOperationRow = ({
  operation,
  transactionTypes,
  theme,
}: any): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      key={operation.id + Math.random().toString(36).substring(7)}
      className={cx('operations-table__row', {
        [`operations-table__row--${theme}`]: theme,
      })}
    >
      <div className="operations-table__cell operations-table__cell--date">
        {dayjs.unix(operation.dateCreate).format('DD.MM.YYYY HH:mm:ss')}
      </div>
      <div className="operations-table__cell operations-table__cell--type">
        {operation?.transactionNumber}
      </div>
      <div
        className={cx(
          'operations-table__cell operations-table__cell--action',
          `operations-table__cell--${
            transactionTypes &&
            transactionTypes[
              operation.transactionType as keyof typeof transactionTypes
            ]
          }`
        )}
        onClick={() => {
          if (operation?.task?.slug) {
            navigate(`/task/${operation?.task?.slug}/`);
          }
        }}
        dangerouslySetInnerHTML={{
          __html: operation.text.replace(/href="([^"]*)"/g, ' '),
        }}
      />
      <div className="operations-table__cell operations-table__cell--sum-change">
        {operation.price &&
          `${
            operation.price > 0 ? `+${operation.price}` : operation.price
          } грн`}
      </div>
      <div className="operations-table__cell operations-table__cell--sum-before">
        {operation.sumBefore && `${operation.sumBefore} грн`}
      </div>
      <div className="operations-table__cell operations-table__cell--sum-after">
        {operation.sumAfter && `${operation.sumAfter} грн`}
      </div>
    </div>
  );
};
