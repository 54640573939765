import React from 'react';

import ProjectsPage from './ProjectsPage/ProjectsPage';
import MainPage from './MainPage/MainPage';
import { checkPermission } from '../../hooks/checkPermission';

const Dashboard = (): JSX.Element =>
  checkPermission('CLIENT') ? <ProjectsPage /> : <MainPage />;

export default Dashboard;
