import React, { InputHTMLAttributes, forwardRef } from 'react';
import cx from 'classnames';

import './Input.scss';

// interface IInputProps {
//   type?: string;
//   value?: string;
//   defaultValue?: string;
//   className?: string;
//   placeholder?: string;
//   style?: React.CSSProperties;
//   onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
// } & React.InputHTMLAttributes<HTMLInputElement>;

type IInputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      value,
      defaultValue,
      onChange,
      children,
      style = {},
      placeholder = '',
      type = 'text',
      ...rest
    },
    ref
  ) => (
    <div className={cx('input', className)}>
      <input
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        className="input__field"
        style={style}
        onChange={onChange}
        {...rest}
      />
      {children}
    </div>
  )
);

export default Input;
