import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';

import Text from '../../../components/UI/Text/Text';

import { IMessage } from '../../../common/types';

import {
  convertBytesToKbOrMbOrGb,
  isTheSameAuthor as isTheSame,
} from '../../../helpers/common';

import './Message.scss';
import { useThemeToggler } from '../../../context/ThemeContext';

import { ReactComponent as IconDownload } from '../../../media/icons/download.svg';
import FileExtensions from '../../../components/UI/FileExtensions/FileExtensions';

import { imgExtensions, videoExtensions } from '../../../helpers/constant';
import User from '../../../components/UsersList/User/User';
import {
  GLOBAL_ROLES_COLORS,
  PROJECT_ROLES_COLORS,
} from '../../../common/constant';

export interface MessageProps {
  message: IMessage;
  isTheSameAuthor?: boolean;
  className?: string;
  bodyClassName?: string;
  setActiveMessage?: any;
  dataMessageId?: string;
  dataEmoji?: string;
  isTheSameDay?: boolean;
  isSystem?: boolean;
  isFileDeleted?: boolean;
  bgColor?: string;
  parentBgColor?: string;
  onOpen?: () => void;
  onScrollTo?: () => void;
  onRecoverMessage?: (message: IMessage) => void;
  onSendEmoji?: (emoji: string, messageId: number) => void;
}

dayjs.locale('uk');

export const Message: React.FC<MessageProps> = ({
  message,
  className,
  bodyClassName,
  setActiveMessage,
  isTheSameAuthor,
  isSystem,
  dataMessageId,
  dataEmoji,
  isTheSameDay,
  isFileDeleted,
  bgColor,
  parentBgColor,
  onOpen = () => {},
  onScrollTo = () => {},
  onRecoverMessage = () => {},
  onSendEmoji = () => {},
}) => {
  const { theme } = useThemeToggler();
  const globalUser = useSelector((state: any) => state.auth);

  useEffect(() => {
    setActiveMessage(message as IMessage);
  }, []);

  const messageDate = message.dateCreate || message?.date;

  const permissionColor = bgColor;
  let textLinkEdited = message?.text.replaceAll('\n', '<br>');

  if (
    typeof message?.text === 'string' &&
    message?.text.includes('<a href="')
  ) {
    textLinkEdited = textLinkEdited.replace(
      /<a /g,
      '<a target="_blank" rel="noopener noreferrer" '
    );
  }

  textLinkEdited.replace(/\n/g, '<br>');

  return (
    <>
      {!isTheSameDay &&
        Date.now() - message.dateCreate / (1000 * 60 * 60 * 24) > 0 && (
          <div className="message__date-separator">
            <Text size="sm" className="message__date">
              {dayjs.unix(message.dateCreate).format('DD MMMM')}
            </Text>
          </div>
        )}
      <div
        className={cx(
          'message',
          {
            'message--deleted': message?.deleted,
            'message--from-the-same-autor': isTheSameAuthor && !isSystem,
            'message--system': isSystem,
            [`message--${theme}`]: theme,
          },
          className
        )}
        data-message-id={dataMessageId}
        data-emoji={dataEmoji}
        style={
          {
            '--color': permissionColor,
          } as React.CSSProperties
        }
      >
        {!isSystem && (
          <div className="message__header">
            {!isTheSameAuthor ? (
              <User
                className="notification-item__avatar"
                isOnline
                user={message?.user}
                size="md"
                backgroundColor={bgColor}
                style={
                  !message?.user?.avatar?.url
                    ? {
                        backgroundColor: permissionColor,
                      }
                    : {}
                }
              />
            ) : !isTheSameDay || !isTheSameAuthor ? (
              <User
                className="notification-item__avatar"
                isOnline
                user={message?.user}
                size="md"
                backgroundColor={bgColor}
                style={
                  !message?.user?.avatar?.url
                    ? {
                        backgroundColor: permissionColor,
                      }
                    : {}
                }
              />
            ) : null}
          </div>
        )}
        <div className="message__inner">
          <div
            className={cx(
              'message__body',
              {
                'message__body--file': message?.file,
                'message__body--system': isSystem,
              },
              bodyClassName
            )}
            // style={{ backgroundColor: isSystem ? '#EFEFEF' : '' }}
          >
            {message?.parentMessage && (
              <div className="message__parent">
                {!isTheSameAuthor && isTheSameDay && (
                  <Text
                    size="sm"
                    className="message__parent-author"
                    style={{
                      color: permissionColor,
                      marginBottom: '7px',
                    }}
                  >
                    {`${message?.user.firstName} ${message?.user.lastName}`}{' '}
                  </Text>
                )}
                <div
                  className={cx('message__parent-inner', {
                    'message__parent-inner--text':
                      !message?.parentMessage?.file,
                  })}
                  style={{
                    borderLeftColor: parentBgColor
                      ? parentBgColor
                      : permissionColor,
                  }}
                >
                  {message?.parentMessage?.file ? (
                    imgExtensions.includes(
                      message?.parentMessage?.file?.extension
                    ) ? (
                      <img
                        src={
                          message?.parentMessage?.file?.thumb ||
                          message?.parentMessage?.file?.url
                        }
                        alt={message?.parentMessage?.file?.url}
                        className="message__parent-photo"
                      />
                    ) : (
                      <FileExtensions
                        className="message__parent-file-label"
                        type={message?.parentMessage?.file?.extension}
                      />
                    )
                  ) : null}
                  <div className="message__parent-header">
                    <div>
                      <Text
                        size="sm"
                        className="message__parent-author"
                        style={{
                          color: parentBgColor
                            ? parentBgColor
                            : permissionColor,
                        }}
                      >
                        {`${message?.parentMessage?.user.firstName} ${message?.parentMessage?.user.lastName}`}
                      </Text>
                    </div>
                    <div
                      className="message__text message__text--parent"
                      onClick={onScrollTo}
                    >
                      {!message?.parentMessage?.file
                        ? message?.parentMessage?.text
                        : imgExtensions.includes(
                            message?.parentMessage?.file?.extension
                          )
                        ? 'Фото'
                        : videoExtensions.includes(
                            message?.parentMessage?.file?.extension
                          )
                        ? 'Відео'
                        : 'Файл'}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(!isTheSameAuthor ||
              (!isTheSameDay && !message.parentMessage)) && (
              <div className="message__top">
                {!message?.parentMessage && !isSystem && (
                  <Text className="message__author" size="sm">
                    {`${message?.user.firstName} ${message?.user.lastName}`}
                  </Text>
                )}
              </div>
            )}
            <div
              className={cx('message__text', {
                'message__text--file': message?.file,
              })}
            >
              {message?.deleted &&
              isTheSame(globalUser.user.id, message.user.id) ? (
                <div
                  className={
                    theme === 'dark'
                      ? 'message__deleted-dark'
                      : 'message__deleted-content'
                  }
                >
                  Повідомлення видалене.
                  <span
                    onClick={() => onRecoverMessage(message)}
                    className="message__restore-btn"
                  >
                    Повернути
                  </span>
                </div>
              ) : (
                <>
                  {!message?.file && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textLinkEdited,
                      }}
                    />
                  )}
                  {isFileDeleted && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textLinkEdited,
                      }}
                    />
                  )}

                  {message?.file && !isFileDeleted && (
                    <div className="message__file">
                      {message?.file.type === 'image' ? (
                        <img
                          onClick={onOpen}
                          className="message__file-img"
                          src={message?.file.thumb}
                          alt={message?.name}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: message?.file.color,
                          }}
                        />
                      ) : (
                        <FileExtensions
                          className="message__file-label"
                          type={message?.file?.extension}
                        />
                      )}
                      <div className="message__file-details">
                        <div className="message__file-name">
                          {message?.file.name}
                        </div>
                        <div className="message__file-size">
                          <a
                            className="message__file-button"
                            href={message?.file.url}
                            target="_blank"
                            download={message?.file.name}
                            rel="noreferrer"
                          >
                            <IconDownload className="message__file-icon" />
                          </a>
                          {convertBytesToKbOrMbOrGb(message?.file.size)}
                        </div>
                      </div>
                      <Text size="sm" className="message__date">
                        {!message?.parentMessage && message?.edited && (
                          <span className="message__edited">змінено </span>
                        )}
                      </Text>
                    </div>
                  )}
                </>
              )}
            </div>
            {message && (
              <div className="message__bottom">
                {message?.emojiList?.length && (
                  <div className="message__reactions">
                    {message?.emojiList?.map((emoji: any) => (
                      <div
                        key={Date.now() * Math.random()}
                        className="message__reaction"
                        onClick={() => onSendEmoji(emoji.emoji, message.id)}
                      >
                        <span
                          className="message__emoji"
                          dangerouslySetInnerHTML={{ __html: emoji.emoji }}
                        />
                        {emoji.users?.map(
                          (user: any): JSX.Element => (
                            <User
                              key={Date.now() * Math.random()}
                              user={user}
                              size="xs"
                              className="message__reaction-user"
                              backgroundColor={
                                user.groupId === 2
                                  ? PROJECT_ROLES_COLORS[user.roleId]
                                  : GLOBAL_ROLES_COLORS[user.groupId]
                              }
                            />
                          )
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {message?.text && (
                  <Text size="sm" className="message__date">
                    {message?.edited && (
                      <span className="message__edited">змінено </span>
                    )}
                    <Tooltip
                      placement="top"
                      overlayStyle={{ transform: 'translateX(60px)' }}
                      trigger={['click']}
                      overlayClassName="message__tooltip-overlay"
                      overlay={
                        <div className="message__tooltip">
                          <div>
                            {dayjs
                              .unix(message.dateCreate)
                              .format('DD.MM.YYYY hh:mm:ss')}
                          </div>
                          {message?.dateEdit && (
                            <div>
                              Змінено:{' '}
                              {dayjs
                                .unix(messageDate)
                                .format('DD.MM.YYYY hh:mm:ss')}
                            </div>
                          )}
                        </div>
                      }
                    >
                      <span>{dayjs.unix(messageDate).format('HH:mm')}</span>
                    </Tooltip>
                  </Text>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
