import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import {
  updateTask,
  updateStatus,
  getTaskData,
  updateTaskPriority,
  uploadFile,
  getFilesList,
  deleteFile,
  createFakeCO,
  updateFileName,
  getMessagesList,
  getTaskDescription,
  getTaskHistory,
} from '../../store/tasks/thunk';

import { useThemeToggler } from '../../context/ThemeContext';
import { AppDispatch } from '../../store';
import { checkPermission } from '../../hooks/checkPermission';
import Button from '../../components/Button/Button';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import DescriptionTask from './DescriptionTask/DescriptionTask';
import AutoResizeTextarea from '../AutoResizeTextarea/AutoResizeTextarea';
import Range from '../../components/UI/Range/Range';
import {
  STATUS_DRAFT,
  STATUS_NEW,
  STATUS_DISCUSS,
  generalChatType,
  PROJECT_ROLES,
  isLocalhost,
  STATUS_IN_WORK,
} from '../../common/constant';
import '../../styles/abstracts/_react-tabs.scss';
import './Task.scss';
import { clearTaskData, TaskState } from '../../store/tasks/store';

import FileUpload from '../../components/FileUpload/F';
import Modal from '../../components/Modal/Modal';
import { Chat } from '../../layout/Chat/Chat';
import CustomSelect from '../../components/UI/CustomSelect/CustomSelect';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import {
  fetchProjectList,
  getProjectData,
  getUsersList,
} from '../../store/projects/thunk';
import { getProjectTasksIdsThatShouldBeWithoutChat } from '../../store/projects/store';
import CommercialOffer, {
  TaskAssessment,
} from '../../components/CommercialOffer/CommercialOffer';
import { scrollToNode } from '../../helpers/common';
import { Toggler } from '../../layout/Header/Header';

import { ReactComponent as IconChat } from '../../media/icons/chat1.svg';
import { ReactComponent as IconTask } from '../../media/icons/task1.svg';
import { useModal } from '../../components/Modal/useModal';
import Gallery from '../../components/Gallery/Gallery';
import Dropzone from '../../components/Dropzone/Dropzone';
import { useSocketListener } from '../../hooks/useSocketListener';
import { setCurrentProjectRoleId } from '../../store/user/store';
import { TUserData } from '../../layout/ProjectList/types';

interface ITaskProps {
  archiveTasks?: any;
}

const Task = ({ archiveTasks = false }: ITaskProps): JSX.Element | null => {
  const dispatch: AppDispatch = useDispatch();
  const { theme } = useThemeToggler();
  const { slug, slugTask } = useParams();
  const navigate = useNavigate();
  const [typeId, setTypeId] = useState<number>(generalChatType);

  const { taskInfo, taskFiles } = useSelector(
    (state: { taskData: TaskState }) => state.taskData
  );
  const { projectList, projectInfo, projectTasksIdsThatShouldBeWithoutChat } =
    useSelector((state: { projectData: any }) => state.projectData);
  const { userInfo } = useSelector(
    (state: { userData: any }) => state.userData
  );

  const taskInfoStatus = taskInfo?.statusId;
  const taskInfoStatusIsNewOrDraft =
    taskInfoStatus === STATUS_NEW || taskInfoStatus === STATUS_DRAFT;

  const taskInfoStatusIsNewOrDraftOrDiscuss =
    taskInfoStatusIsNewOrDraft || taskInfoStatus === STATUS_DISCUSS;

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const isArchiveTaskWasNewOrDraft = useMemo(() => {
    if (projectTasksIdsThatShouldBeWithoutChat) {
      return projectTasksIdsThatShouldBeWithoutChat.includes(taskInfo?.id);
    } else {
      return false;
    }
  }, [projectTasksIdsThatShouldBeWithoutChat, taskInfo?.id, taskInfoStatus]);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { fileRules } = useSelector(
    (state: { userData: any }) => state.userData
  );

  const onceChatToggled = useRef<boolean>(true);
  const formRef = useRef<HTMLFormElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const taskHeaderRef = useRef<HTMLDivElement>(null);
  const taskBodyRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefChat = useRef<HTMLInputElement>(null);
  const inputRefChatMobile = useRef<HTMLInputElement>(null);
  const footerChatRef = useRef<HTMLInputElement>(null);
  const once = useRef<boolean>(true);

  const [projectInfoFirstFetch, setProjectInfoFirstFetch] =
    useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isSelectActive, setIsSelectActive] = useState<boolean>(false);
  const [mobileChatVisible, setMobileChatVisible] = useState<boolean>(false);
  const [galleryVisible, setGalleryVisible] = useState<boolean>(false);
  const [dropzoneChatFiles, setDropzoneChatFiles] = useState<File[]>([]);
  const [dropzoneTaskFiles, setDropzoneTaskFiles] = useState<File[]>([]);
  const [rangeClickBlocked, setRangeClickBlocked] = useState(false);
  const [rangeCOClickBlocked, setRangeCOClickBlocked] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const outsideClickHandler = useCallback(
    (e: any) => {
      if (e.target.classList.contains('task__move-btn')) return;
      if (isSelectActive) {
        setIsSelectActive(false);
      }
    },
    [isSelectActive]
  );
  useOnClickOutside(selectRef, outsideClickHandler);

  const [itemModalOpen, setItemModalOpen] = useModal();

  const taskEventHandler = useCallback(
    (data: any) => {
      if (data.slug === slugTask) {
        dispatch(getTaskData(slugTask as string));
      }
    },
    [slugTask]
  );

  const taskTextEventHandler = useCallback(
    (data: any) => {
      if (data.slug === slugTask) {
        dispatch(getTaskDescription(slugTask));
        dispatch(getTaskHistory(slugTask as string));
        if (checkPermission('CLIENT')) {
          dispatch(getProjectData(taskInfo?.project.slug as string));
        }
      }
    },
    [slugTask, taskInfo?.project.slug]
  );

  const taskFileEventHandler = useCallback(
    (data: any) => {
      if (data.slug === slugTask) {
        dispatch(
          getFilesList({
            typeId,
            slug: slugTask,
            fileUserId: typeId === 3 ? userInfo.id : null,
          })
        );
        if (checkPermission('CLIENT')) {
          dispatch(getProjectData(taskInfo?.project.slug as string));
        }
      }
    },
    [slugTask, taskInfo, typeId]
  );

  const projectTaskEventHandler = useCallback(
    (data: any) => {
      if (!slugTask || !taskInfo?.project.slug) return;
      if (data.slug === slugTask) {
        dispatch(getProjectData(data.pSlug))
          .unwrap()
          .catch(() => {
            navigate(`/project/${taskInfo?.project.slug}/`);
          });
      } else {
        if (checkPermission('CLIENT')) {
          dispatch(getProjectData(taskInfo?.project.slug as string));
        }
      }
    },
    [slugTask, taskInfo]
  );

  useSocketListener('taskEvent', taskEventHandler);
  useSocketListener('taskTextEvent', taskTextEventHandler);
  useSocketListener('taskFile', taskFileEventHandler);
  useSocketListener('projectTaskEvent', projectTaskEventHandler);

  useLayoutEffect(() => {
    if (slugTask && taskInfo?.project?.slug) {
      // dispatch(getUsersList(taskInfo?.project.slug as string))
      //   .unwrap()
      //   .then((e: any) => {
      //     if (!userInfo?.id) return;
      //     dispatch(
      //       setCurrentProjectRoleId(
      //         e?.find((user: TUserData) => user.id === userInfo?.id)?.roleId
      //       )
      //     );
      //   });
      // rewrite this code with async/await
      (async () => {
        try {
          const users = await dispatch(
            getUsersList(taskInfo?.project.slug as string)
          ).unwrap();
          if (!userInfo?.id) return;
          dispatch(
            setCurrentProjectRoleId(
              users?.find((user: TUserData) => user.id === userInfo?.id)?.roleId
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [slugTask, userInfo?.id, taskInfo?.project?.slug]);

  useEffect(
    () => () =>
      !projectInfoFirstFetch &&
      (dispatch(getProjectTasksIdsThatShouldBeWithoutChat()) as any),
    [projectInfo?.stageList, projectInfoFirstFetch, taskInfoStatus]
  );

  useEffect(() => {
    if (taskInfo?.name?.length > 50) {
      document.body?.style.setProperty('--task-name-height-178', '178px');
    } else {
      document.body?.style.setProperty('--task-name-height-178', '136px');
    }
  }, [taskInfo?.name]);

  // useEffect(
  //   () => () =>
  //     projectInfoFirstFetch &&
  //     (dispatch(getProjectTasksIdsThatShouldBeWithoutChat()) as any),
  //   [taskInfoStatus, projectInfoFirstFetch]
  // );

  useEffect(() => {
    if (!isMobile) {
      setMobileChatVisible(false);
    }
    // setTimeout(() => {
    //   document.body?.style.setProperty(
    //     '--task-name-height',
    //     `${formRef?.current?.offsetHeight}px`
    //   );
    // }, 1000);
  }, [isMobile]);

  useEffect(() => {
    // here is for mobile adaptive task name height with sticky position
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && formRef?.current) {
          document.body?.style.setProperty(
            '--task-name-height',
            `${formRef?.current?.offsetHeight - 8}px`
          );
        }
      }
    });

    if (formRef.current) {
      observer.observe(formRef.current, {
        attributes: true,
        childList: false,
        subtree: false,
      });
    }

    return () => {
      observer.disconnect();
    };
  });

  useEffect(() => {
    if (taskInfo?.groups?.length) {
      const container = document.querySelector('.task__body');
      if (container) {
        container.scrollTo(0, 0);
      }
    }
  }, [taskInfo?.groups?.length, isMobile, slugTask]);

  useEffect(() => {
    setIsSelectActive(false);
    const getCurrentTaskData = async (): Promise<any> => {
      try {
        const payload: any = await dispatch(
          getTaskData(slugTask as string)
        ).unwrap();
        setLoaded(true);
        await dispatch(getTaskDescription(slugTask));
        await dispatch(
          getFilesList({
            typeId,
            slug: slugTask,
            fileUserId: typeId === 3 ? userInfo.id : null,
          })
        );
        if (checkPermission('CLIENT')) {
          await dispatch(getProjectData(payload.project.slug as string));
          await dispatch(getUsersList(payload.project.slug as string));
        }
      } catch (e) {
        setItemModalOpen(false);
        navigate('/');
      }
    };

    if (slugTask) {
      getCurrentTaskData();
    }
  }, [slugTask, typeId]);

  useEffect(() => {
    if (checkPermission('CLIENT')) {
      dispatch(fetchProjectList());
    }
    scrollToNode('message', document.querySelectorAll('.message').length - 1);
  }, []);

  useLayoutEffect(
    () => () => {
      dispatch(clearTaskData());
      // setItemModalOpen(false);
    },
    [slugTask, slug]
  );

  useLayoutEffect(() => {
    if ((userInfo.roleId || checkPermission('STAFF')) && loaded) {
      setItemModalOpen(true);
    }
    return () => {
      setItemModalOpen(false);
    };
  }, [userInfo.roleId, slugTask, loaded]);

  const handlePost = async (): Promise<any> => {
    await dispatch(updateStatus({ action: 'public', slug: taskInfo.slug }));
    await dispatch(getProjectData(taskInfo.project.slug as string));
    await dispatch(getTaskData(slugTask as string));
  };

  const handleDiscuss = async (): Promise<any> => {
    await dispatch(updateStatus({ action: 'discuss', slug: taskInfo.slug }));
    await dispatch(getProjectData(taskInfo.project.slug as string));
  };

  const handleBackToDraft = async (): Promise<any> => {
    await dispatch(updateStatus({ action: 'draft', slug: taskInfo.slug }));
    await dispatch(getProjectData(taskInfo.project.slug as string));
    await dispatch(getTaskData(slugTask as string));
  };

  const handleDelete = async (): Promise<any> => {
    await dispatch(
      updateStatus({
        action: 'deleteClient',
        // slug: taskInfo.slug,
        slug: slugTask,
        value: getValues('name'),
        param: 'text',
      })
    );
    setLoaded(false);
    setItemModalOpen(false);
    await dispatch(getProjectData(taskInfo.project.slug as string));
    setIsDelete(false);
    setValue('name', '');
    navigate(`/project/${taskInfo.project.slug}/`);
  };

  const handleMoveTask = async (value: string): Promise<any> => {
    await dispatch(
      updateTask({
        param: 'projectId',
        value,
        slug: taskInfo.slug,
      })
    );
    await dispatch(fetchProjectList());
    const movedProjectSlug = projectList.find(
      (project: any) => project.id === value
    ).slug;
    dispatch(getProjectData(movedProjectSlug));
  };

  const handleChangeNameTask = async (value: string): Promise<any> => {
    if (value === taskInfo?.name) return;
    await dispatch(
      updateTask({ value: value?.trim(), param: 'name', slug: taskInfo.slug })
    );
    // await dispatch(getProjectData(taskInfo?.project.slug as string));
  };

  const handleChangeDescription = useCallback(
    async (value: string): Promise<any> => {
      await dispatch(updateTask({ value, param: 'text', slug: taskInfo.slug }));
      // await dispatch(getProjectData(taskInfo?.project.slug as string));
      // await dispatch(getTaskData(slugTask as string));
    },
    [taskInfo?.slug]
  );

  const handleRenameFile = async (
    fileId: number,
    name: string
  ): Promise<any> => {
    await dispatch(
      updateFileName({
        fileId,
        typeId,
        name,
        slug: taskInfo.slug,
      })
    );
    // await dispatch(getFilesList({ typeId: 1, slug: slugTask }));
  };

  const handleChangeRating = useCallback(
    async (value: number): Promise<any> => {
      if (rangeClickBlocked) return;
      setRangeClickBlocked(true);
      try {
        await dispatch(updateTaskPriority({ ...taskInfo, priority: value }));
      } catch (e) {
        console.error(e);
      } finally {
        setRangeClickBlocked(false);
      }
      await dispatch(getProjectData(taskInfo?.project.slug as string));
    },
    [rangeClickBlocked, taskInfo, typeId]
  );

  const photosFromGallery = [] as any[];
  taskFiles
    ?.slice()
    .reverse()
    .forEach((item: any) => {
      if (item.type === 'image') {
        photosFromGallery.push(item.url);
      }
    });

  const handlerSetIndex = useCallback(
    (file: any) => {
      setActiveIndex(
        taskFiles
          .slice()
          .reverse()
          .filter((item: any) => item.type === 'image')
          .findIndex((item: any) => item.id === file.id)
      );
      setGalleryVisible(true);
    },
    [taskFiles]
  );

  const isChatDisabled = useMemo(
    () =>
      taskInfo?.textStatus === 'archive' ||
      (checkPermission('STAFF') && !taskInfo?.chatEnable && typeId === 1),
    [taskInfo?.chatEnable, taskInfo?.textStatus, typeId]
  );

  const handleAddFile = useCallback(
    async (data: any, config: any): Promise<any> => {
      data.append('typeId', typeId);
      if (typeId === 3) {
        data.append('fileUserId', userInfo.id);
      }
      try {
        await dispatch(
          uploadFile({
            data,
            slug: slugTask,
            typeId,
            config,
          })
        );
        await dispatch(
          getFilesList({
            typeId,
            slug: slugTask,
            fileUserId: typeId === 3 ? userInfo.id : null,
          })
        );
        await dispatch(
          getMessagesList({
            typeId,
            slug: slugTask as string,
            userId: typeId === 3 ? userInfo.id : null,
          })
        );
        scrollToNode(
          'message',
          document.querySelectorAll('.message')?.length - 1
        );
      } catch (e) {
        console.error(e);
      }
    },
    [slugTask, typeId]
  );

  const handleDeleteFile = useCallback(
    async (id: number): Promise<any> => {
      const result = confirm('Ви впевнені, що хочете видалити файл?');
      if (!result) return;
      // dispatch(
      //   deleteFile({
      //     slug: taskInfo?.slug,
      //     fileId: id,
      //   })
      // )
      //   .unwrap()
      //   .then(() => {
      //     dispatch(getFilesList({ typeId: 1, slug: slugTask }));
      //   });
      // rewrite this code with async/await
      try {
        await dispatch(
          deleteFile({
            slug: taskInfo?.slug,
            fileId: id,
          })
        );
        await dispatch(
          getFilesList({
            typeId,
            slug: slugTask,
            fileUserId: typeId === 3 ? userInfo.id : null,
          })
        );
      } catch (e) {
        console.error(e);
      }
    },
    [taskInfo]
  );

  const projects = useMemo(() => {
    const projectsOptions: any = [];
    projectList?.forEach(
      (item: any) =>
        item.slug !== taskInfo?.project.slug &&
        projectsOptions.push({
          label: item.name,
          value: item.id,
          slug: item.slug,
          id: item.id,
        })
    );
    return projectsOptions;
  }, [projectList, taskInfo, slugTask, slug]);

  const canDeleteTask = useMemo(
    () =>
      userInfo.roleId === PROJECT_ROLES.productOwner ||
      userInfo.id === taskInfo?.user?.id,
    [userInfo?.roleId, taskInfo?.user?.id]
  );

  const chooseArchiveVersionHandler = useCallback(
    (e: { label: string; value: string; slug: string; id: string }) => {
      if (projectInfo.value !== taskInfo.project.id) {
        handleMoveTask(e.id);
        setIsSelectActive(false);
      }
    },
    [projectList, taskInfo, slugTask, slug]
  );

  const dropzoneTaskAddFilesCallback = useCallback((files: any): any => {
    setDropzoneTaskFiles(files);
  }, []);

  const dropzoneChatAddFilesCallback = useCallback((files: any): any => {
    setDropzoneChatFiles(files);
  }, []);

  const closeModalHandler = useCallback(() => {
    if (once.current) {
      if (checkPermission('CLIENT')) {
        navigate(`/project/${taskInfo?.project.slug}/`);
      } else if (checkPermission('STAFF')) {
        navigate('/tasks/');
      }
      once.current = false;
    }
    setItemModalOpen(false);
  }, [slugTask, slug, taskInfo?.project?.slug]);

  const renderPostTask = (): JSX.Element => (
    <Button view="third" type="button" onClick={handleBackToDraft}>
      Повернути до чернеток
    </Button>
  );
  // checkPermission('CLIENT') ? (
  //   <Button view="third" type="button" onClick={handleBackToDraft}>
  //     Повернути до чернеток
  //   </Button>
  // ) : !isLocalhost() && userInfo.roleId === PROJECT_ROLES.productOwner ? (
  //   <Button view="default" type="button" onClick={handleDiscuss}>
  //     До обговорення
  //   </Button>
  // ) : (
  //   (null as any)
  // );

  const changeMobileVisible = useCallback(() => {
    setMobileChatVisible((prev) => !prev);
    const container = document.querySelector('.task__body');
    console.log(onceChatToggled.current);
    if (!mobileChatVisible) {
      if (container && onceChatToggled.current) {
        setTimeout(() => {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
          onceChatToggled.current = false;
        }, 10);
      }
    } else {
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        onceChatToggled.current = true;
      }
    }
  }, [mobileChatVisible]);

  if (checkPermission('CLIENT') && (!userInfo.roleId || !taskInfo?.name)) {
    return null;
  }

  return (
    <Modal
      key={taskInfoStatus}
      isActive={itemModalOpen}
      modalLiveButtonClassName={isMobile ? 'task__close-btn-mobile' : ''}
      handleClose={closeModalHandler}
    >
      <div
        className={cx('task', {
          [`task--${theme}`]: theme,
        })}
      >
        <div
          className={cx('task__column', 'task__column--description', {
            'task__column--full':
              (isArchiveTaskWasNewOrDraft && !checkPermission('STAFF')) ||
              taskInfoStatusIsNewOrDraft,
          })}
          style={
            {
              '--header-height':
                taskHeaderRef.current &&
                `${taskHeaderRef.current!.offsetHeight}px`,
            } as CSSProperties
          }
        >
          {taskInfoStatusIsNewOrDraft && (
            <Dropzone
              addFiles={dropzoneTaskAddFilesCallback}
              targetClassName="task__column--description"
            />
          )}
          <div
            ref={taskHeaderRef}
            className={cx('task__header', {
              'task__header--mobile': isMobile,
            })}
          >
            <Modal
              size="sm"
              headerAccentBackground
              title="Відмінити завдання"
              isActive={isDelete}
              handleClose={() => setIsDelete(false)}
              leftButton={false}
            >
              <div
                className={cx('task__delete-block delete-block', {
                  [`delete-block--${theme}`]: theme,
                })}
              >
                <textarea
                  className="delete-block__textarea"
                  placeholder="Вкажіть причину відміни завдання"
                  style={{ borderColor: errors.name && '#FF0000' }}
                  {...register('name', { required: true })}
                />
                <Button
                  className="delete-block__button"
                  view="warning"
                  variant="fill"
                  onClick={handleSubmit(handleDelete)}
                >
                  Відмінити
                </Button>
              </div>
            </Modal>
            {taskInfoStatus === STATUS_NEW && (
              <Button
                className="task__public"
                view="default"
                type="button"
                onClick={handlePost}
                style={taskInfo?.type === 1 ? { background: '#FF0000' } : {}}
              >
                {taskInfo?.type === 0
                  ? 'Опублікувати'
                  : 'Опублікувати терміново'}
              </Button>
            )}
            {checkPermission('CLIENT') &&
              isLocalhost() &&
              taskInfoStatus === STATUS_DRAFT &&
              userInfo.roleId === PROJECT_ROLES.productOwner && (
                <Button
                  style={{ color: '#fff' }}
                  view="success"
                  onClick={handleDiscuss}
                >
                  До обговорення
                </Button>
              )}
            {taskInfoStatus === STATUS_DRAFT && renderPostTask()}
            {isLocalhost() &&
              taskInfoStatus === STATUS_DISCUSS &&
              checkPermission('CLIENT') && (
                <Button
                  view="default"
                  onClick={() => {
                    dispatch(
                      createFakeCO({
                        slug: taskInfo.slug,
                      })
                    );
                  }}
                >
                  Тест КП
                </Button>
              )}
            {taskInfoStatusIsNewOrDraft && projectList?.length > 1 && (
              <div className="task__select-wrapper">
                <Button
                  view="secondary"
                  type="button"
                  className={cx('task__move-btn', {
                    [`task__header-button--${theme}`]: theme,
                  })}
                  onClick={() => {
                    setIsSelectActive((prev) => !prev);
                    if (checkPermission('CLIENT')) {
                      dispatch(
                        getProjectData(taskInfo?.project.slug as string)
                      );
                    }
                  }}
                >
                  <span
                    style={{
                      transform: `rotate(${isSelectActive ? 0 : 180}deg)`,
                      left: isSelectActive ? '0' : '-1px',
                    }}
                    className="ci ci-sub_right task__move-icon"
                  />
                </Button>
                {isSelectActive && (
                  <div ref={selectRef}>
                    <CustomSelect
                      onChange={chooseArchiveVersionHandler}
                      placeholder={taskInfo.project.name}
                      className="task__move-select"
                      options={projects}
                    />
                  </div>
                )}
              </div>
            )}
            {taskInfoStatusIsNewOrDraftOrDiscuss && canDeleteTask && (
              <Button
                className={cx('task__delete-btn', {
                  [`task__delete-btn--${theme}`]: theme,
                })}
                view="warning"
                type="button"
                onClick={() => setIsDelete(true)}
              >
                <span className="ci ci-trash_full task__delete-icon" />
              </Button>
            )}
            <div
              style={{
                height:
                  taskInfoStatus >= 400 && taskInfoStatus < 500 ? '35px' : 0,
              }}
            />
            {taskInfoStatus >= 300 && taskInfoStatus < 500 && (
              <ProgressBar value={taskInfo?.percent} />
            )}
            {(taskInfoStatusIsNewOrDraft ||
              (taskInfoStatus === STATUS_DISCUSS &&
                !taskInfo.groups.length)) && (
              <Range
                disabled={rangeClickBlocked}
                // key={Date.now() * Math.random()}
                key={taskInfo?.priority}
                initialValue={taskInfo.priority}
                className="task__range"
                onMouseUp={handleChangeRating}
              />
            )}
          </div>
          <div
            tabIndex={0}
            style={{
              outline: 'none',
              paddingBottom: mobileChatVisible ? '0' : '16px',
            }}
            className="task__body"
          >
            <div
              className={cx({
                'task__mobile-top': taskInfoStatusIsNewOrDraft,
              })}
            >
              {checkPermission('CLIENT') || checkPermission('STAFF') ? (
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className={cx({
                    'task__mobile-top': !taskInfoStatusIsNewOrDraft,
                  })}
                >
                  <AutoResizeTextarea
                    style={
                      isMobile
                        ? {
                            maxHeight: '78px',
                            overflowY: 'auto',
                          }
                        : {}
                    }
                    ref={formRef}
                    handleChangeName={handleChangeNameTask}
                    name={
                      taskInfoStatus >= 500
                        ? `[Архів] ${taskInfo?.name?.trim()}`
                        : taskInfo?.name?.trim()
                    }
                    disabled={!taskInfoStatusIsNewOrDraft}
                    onBlur={() => {
                      (formRef.current as any)!.style!.maxHeight = '75px';
                      (formRef.current as any)!.style!.overflowY = 'auto';
                    }}
                    onFocus={() => {
                      if (isMobile) {
                        (formRef.current as any)!.style!.maxHeight = 'none';
                        (formRef.current as any)!.style!.overflowY = 'auto';
                      }
                    }}
                    sendByEnter
                    maxLength={Infinity}
                    className="task__textarea"
                    defaultHeight={projectInfo?.name?.length > 150 ? 160 : 30}
                  />
                  {isMobile &&
                    taskInfoStatus !== STATUS_NEW &&
                    taskInfoStatus !== STATUS_DRAFT && (
                      <Toggler
                        theme={theme}
                        defaultChecked={mobileChatVisible}
                        className="task__description-toggler"
                        overlayClassName="task__description-toggler-overlay"
                        onChange={changeMobileVisible}
                      >
                        <IconTask className="task__description-toggler-task" />
                        <div className="task__description-toggler-chat">
                          <IconChat />
                        </div>
                      </Toggler>
                    )}
                </div>
              ) : (
                taskInfo?.name
              )}
              {!!taskInfo?.groups?.length &&
                !mobileChatVisible &&
                !checkPermission('STAFF') && (
                  <div
                    className={cx('task__checks', {
                      'task__checks--co': taskInfo.groups.length === 1,
                    })}
                  >
                    {taskInfo.groups.map((group: any) => (
                      <CommercialOffer
                        setRangeClickBlocked={setRangeCOClickBlocked}
                        rangeClickBlocked={rangeCOClickBlocked}
                        priority={group.priority}
                        slug={taskInfo.slug}
                        group={group}
                        key={group.id}
                        taskId={taskInfo.id}
                      />
                    ))}
                  </div>
                )}

              {checkPermission('STAFF') && (
                <TaskAssessment task={taskInfo} isMobile={isMobile} />
              )}

              <hr className="task__line" />
            </div>
            {!mobileChatVisible ? (
              <div className="task__tabs">
                <div>
                  <DescriptionTask
                    archiveTasks={archiveTasks}
                    inputRef={inputRef}
                    taskFiles={taskFiles}
                    onChangeDescription={handleChangeDescription}
                  />
                </div>
                <FileUpload
                  supportedExtensions={fileRules?.file?.extensions}
                  dndFiles={dropzoneTaskFiles}
                  maxFileSize={fileRules?.file?.maxSize}
                  nodeRef={
                    taskInfoStatusIsNewOrDraft
                      ? footerChatRef.current
                      : undefined
                  }
                  canDelete={taskInfoStatusIsNewOrDraft}
                  ref={inputRef}
                  taskId={taskInfo?.id}
                  fileItems={taskFiles}
                  handleAddFile={handleAddFile}
                  onDeleteFile={handleDeleteFile as any}
                  onRenameFile={handleRenameFile}
                  onOpenGallery={handlerSetIndex}
                  withFiles
                />
              </div>
            ) : (
              <div className="task__messages-block">
                <FileUpload
                  supportedExtensions={fileRules?.file?.extensions}
                  dndFiles={dropzoneTaskFiles}
                  maxFileSize={fileRules?.file?.maxSize}
                  nodeRef={footerChatRef.current}
                  canDelete={taskInfoStatusIsNewOrDraft}
                  ref={inputRefChatMobile}
                  taskId={taskInfo?.id}
                  fileItems={taskFiles}
                  handleAddFile={handleAddFile}
                  onDeleteFile={handleDeleteFile as any}
                  onRenameFile={handleRenameFile}
                  onOpenGallery={handlerSetIndex}
                  withFiles={!mobileChatVisible}
                />
                <Chat
                  setTypeId={setTypeId}
                  typeId={typeId}
                  disabled={isChatDisabled}
                  isVisible={mobileChatVisible}
                  className={cx('task__mobile-chat', {
                    'task__mobile-chat--active': mobileChatVisible,
                    'task__mobile-chat--in-work':
                      taskInfoStatus === STATUS_IN_WORK,
                  })}
                  chatPlaceholder={mobileChatVisible && 'Введіть текст'}
                  defaultTextareaHeight={10}
                  nodeRef={footerChatRef}
                  inputRef={inputRefChatMobile}
                />
              </div>
            )}
          </div>
        </div>
        {(!isArchiveTaskWasNewOrDraft || checkPermission('STAFF')) &&
          taskInfoStatus !== STATUS_DRAFT &&
          taskInfoStatus !== STATUS_NEW &&
          !isMobile && (
            <div
              className={cx('task__column', 'task__column--chat', {
                'task__column--animated': taskInfoStatusIsNewOrDraft,
              })}
            >
              {' '}
              {taskInfo?.textStatus !== 'archive' && (
                <FileUpload
                  maxFileSize={fileRules?.file?.maxSize}
                  ref={inputRefChat}
                  supportedExtensions={fileRules?.file?.extensions}
                  dndFiles={dropzoneChatFiles}
                  handleAddFile={handleAddFile}
                  taskId={taskInfo?.id}
                  fileItems={taskFiles}
                  onDeleteFile={handleDeleteFile as any}
                  nodeRef={footerChatRef.current}
                  onRenameFile={handleRenameFile}
                  onOpenGallery={() => setGalleryVisible(true)}
                />
              )}
              <div className="task__messages-block">
                {taskInfo?.textStatus !== 'archive' && (
                  <Dropzone
                    addFiles={dropzoneChatAddFilesCallback}
                    targetClassName="task__column--chat"
                  />
                )}
                <Chat
                  setTypeId={setTypeId}
                  typeId={typeId}
                  disabled={isChatDisabled}
                  nodeRef={footerChatRef}
                  inputRef={inputRefChat}
                />
              </div>
            </div>
          )}
      </div>
      {galleryVisible && (
        <Gallery
          index={activeIndex}
          images={photosFromGallery}
          closeLightbox={() => setGalleryVisible(false)}
          onDownload={(i) => {
            window.open(photosFromGallery[i]);
          }}
          canDelete={false}
        />
      )}
    </Modal>
  );
};

export default memo(Task);
