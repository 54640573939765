import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  forwardRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend';

import ProjectCard from './ProjectCard/ProjectCard';
import { useThemeToggler } from '../../context/ThemeContext';
import CreateProject from '../../forms/CreateProject/CreateProject';

import Spinner from '../../components/UI/Spinner/Spinner';

import { TProjectData } from './types';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { AppDispatch } from '../../store';

import './ProjectList.scss';
import { fetchProjectList, sortProjects } from '../../store/projects/thunk';
import { ProjectState } from '../../store/projects/store';

const ProjectList = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [isCreateProjectForm, toggleCreateProjectForm] =
    useState<boolean>(false);
  const { theme } = useThemeToggler();
  const labelRef = useRef<HTMLLabelElement>(null);
  const { projectList, projectListLoading } = useSelector(
    (state: { projectData: ProjectState }) => state.projectData
  );

  const ref = useRef<HTMLLIElement>(null);
  useOnClickOutside(ref, () => {
    if (!isInputHaveValueCondition()) {
      toggleCreateProjectForm(false);
    }
  });

  const handleCreateProject = (): void => {
    toggleCreateProjectForm(true);
  };

  const openProjectPage = useCallback(
    (slug: string): void => {
      if (!params.slug) {
        navigate(`/project/${slug}/`);
      }
    },
    [params]
  );

  const isInputHaveValueCondition = (): string | boolean => {
    if (labelRef.current) {
      return (
        labelRef.current!.nextElementSibling as HTMLInputElement
      ).value.trim();
    } else return false;
  };

  const [cards, setCards] = useState(projectList);

  useEffect(() => {
    dispatch(fetchProjectList());
  }, []);

  useEffect(() => {
    if (projectList?.length) {
      setCards(projectList);
    }
  }, [projectList]);

  const moveCard = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      const projects: any = {};
      projectList?.forEach((item, i) => {
        projects[item.slug] = i;
      });

      const dragCard = cards[dragIndex];
      console.log('dasd');
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards, projectList]
  );

  const handleDrop = useCallback(() => {
    const projectsData: any = {};
    cards.forEach((ite, i) => {
      projectsData[ite.slug] = i;
    });
    dispatch(sortProjects(projectsData));
  }, [cards]);

  // const renderCard = (item: any, index: number): any => {
  //   console.log(item);
  //   return (
  //     <ProjectItem
  //       projects={projectList}
  //       key={item?.id}
  //       id={item?.id}
  //       item={item}
  //       index={index}
  //       moveCard={moveCard}
  //       theme={theme}
  //       openProjectPage={openProjectPage}
  //       handleDrop={handleDrop}
  //     />
  //   );
  // };

  if (projectListLoading) {
    return <Spinner size="lg" className="task-loading" center />;
  }

  return (
    <DndProvider
      backend={HTML5Backend}
      // debugMode={true}
      // context={window}
      options={{
        enableMouseEvents: true,
        ignoreContextMenu: true,
      }}
    >
      {/* <CustomDragLayer /> */}
      <ul className="list project-list">
        {cards?.map(
          (item: TProjectData, i: number): JSX.Element => (
            // <React.Fragment key={item.id}>
            //   <DraggableBox
            //     projects={projectList}
            //     key={item?.id}
            //     id={item?.id}
            //     item={item}
            //     index={i}
            //     moveCard={moveCard}
            //     theme={theme}
            //     openProjectPage={openProjectPage}
            //     handleDrop={handleDrop}
            //   />
            // </React.Fragment>
            <ProjectItem
              projects={projectList}
              key={item?.id}
              theme={theme}
              id={item?.id}
              item={item}
              index={i}
              moveCard={moveCard}
              // theme={theme}
              openProjectPage={openProjectPage}
              handleDrop={handleDrop}
            />
          )
        )}
        <li
          className={`project-card project-card--custom project-card--${theme}`}
          ref={ref}
        >
          <button
            type="button"
            className="project-card__container"
            onClick={handleCreateProject}
          >
            {isCreateProjectForm ? (
              <CreateProject
                ref={labelRef}
                hideCreateProjectForm={toggleCreateProjectForm}
              />
            ) : (
              <span className="project-card__headline">
                Створити новий проєкт
              </span>
            )}
          </button>
        </li>
      </ul>
    </DndProvider>
  );
};

const ProjectItem = memo(
  ({
    openProjectPage,
    item,
    id,
    theme,
    moveCard,
    index,
    handleDrop,
  }: any): JSX.Element => {
    const ref = useRef(null);

    const [{ isOver }, drop] = useDrop({
      accept: 'card',
      drop: handleDrop,
      options: {
        dropEffect: 'move',
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover(it: any, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = it.index;
        const hoverIndex = index;
        // if (dragIndex === hoverIndex) {
        //   return;
        // }
        const hoverBoundingRect = (ref.current as any).getBoundingClientRect();
        // console.log(it, hoverBoundingRect.right);
        const clientOffset = monitor.getClientOffset();

        // here is the logic for horizontal drag and drop
        const hoverMiddleX =
          (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        const hoverClientX = clientOffset!.x - hoverBoundingRect.left / 2;

        if (dragIndex > hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }

        it.index = hoverIndex;
        moveCard(dragIndex, hoverIndex);
      },
    });
    const [{ isDragging }, drag, preview]: any = useDrag({
      item: {
        id,
        index,
      },
      type: 'card',
      collect: (monitor: any) => ({
        isDragging: !!monitor.isDragging(),
      }),
    } as any);

    drag(drop(ref));

    useEffect(() => {
      preview(getEmptyImage(), {
        captureDraggingState: true,
        captureBounds: true,
      });
    }, []);

    // const backgroundColor = !isDragging ? '#fff' : 'rgba(0,0,0,.15)';
    const backgroundColor = isDragging && 'rgba(0,0,0,.15)';

    return (
      <li
        ref={ref}
        className={`project-card project-card--${theme}`}
        style={{ backgroundColor }}
      >
        <button
          type="button"
          className="project-card__wrapper"
          onClick={() => {
            openProjectPage(item.slug);
          }}
        >
          <ProjectCard project={item} />
        </button>
      </li>
    );
  }
);

export default ProjectList;
// ----------------------

const RenderCard = forwardRef(
  (
    { theme, backgroundColor, item, openProjectPage }: any,
    ref
  ): JSX.Element => (
    <li
      ref={ref as any}
      className={`project-card project-card--${theme}`}
      style={{ backgroundColor }}
    >
      <button
        type="button"
        className="project-card__wrapper"
        onClick={() => {
          openProjectPage(item.slug);
        }}
      >
        <ProjectCard project={item} />
      </button>
    </li>
  )
);

const RenderCardPreview = (): JSX.Element => (
  <div style={{ border: '2px solid red' }}>
    {/* <RenderCard
      item={item}
      theme={theme}
      projects={projects}
      backgroundColor={backgroundColor}
    /> */}
    <div
      style={{
        width: '100px',
        height: '100px',
        background: 'red',
      }}
    />
  </div>
);

const DraggableBox = memo(
  ({
    openProjectPage,
    item,
    id,
    theme,
    moveCard,
    index,
    handleDrop,
    projects,
  }: any): JSX.Element => {
    const ref = useRef(null);

    const [{ isOver }, drop] = useDrop({
      accept: 'card',
      drop: handleDrop,
      options: {
        dropEffect: 'move',
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover(it: any, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = it.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = (ref.current as any).getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();

        // here is the logic for horizontal drag and drop
        const hoverMiddleX =
          (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        const hoverClientX = clientOffset!.x - hoverBoundingRect.left / 2;

        if (dragIndex > hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }

        it.index = hoverIndex;
        moveCard(dragIndex, hoverIndex);
      },
    });
    const [{ isDragging }, drag, preview]: any = useDrag({
      item: {
        id,
        index,
      },
      type: 'card',
      collect: (monitor: any) => ({
        isDragging: !!monitor.isDragging(),
      }),
    } as any);

    drag(drop(ref));

    useEffect(() => {
      console.log(242);
      preview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }, []);

    const backgroundColor = !isDragging ? '#fff' : 'rgba(0,0,0,.15)';

    return (
      <div>
        {/* {projects.map((i: any) => ( */}
        <RenderCard
          ref={ref}
          item={item}
          theme={theme}
          projects={projects}
          backgroundColor={backgroundColor}
        />
        {/* ))} */}
      </div>
    );
  }
);

const CustomDragLayer = (): JSX.Element | null => {
  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));
  function renderItem(): any {
    switch (itemType) {
      case 'card':
        return (
          <RenderCardPreview
          // item={i}
          // theme={theme}
          // projects={projects}
          // backgroundColor={backgroundColor}
          />
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div>
      <div>{renderItem()}</div>
    </div>
  );
};
