import axios, { AxiosRequestConfig } from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const templateOptions = { ...defaultOptions, baseURL };

export const instance = axios.create(templateOptions);
export const projectInstance = axios.create(templateOptions);
export const projectInstanceWithFiles = axios.create(templateOptions);

const tokenInstanceMiddleware =
  (files = false) =>
  async (req: AxiosRequestConfig): Promise<any> => {
    const tokenFromLocalStorage = localStorage.getItem('token');

    req.headers = {
      Authorization: `Bearer ${tokenFromLocalStorage}`,
      'Content-Type': files ? 'multipart/form-data' : 'application/json',
    };

    return req;
  };

projectInstance.interceptors.request.use(tokenInstanceMiddleware());
projectInstanceWithFiles.interceptors.request.use(
  tokenInstanceMiddleware(true)
);
