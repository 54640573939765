import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { AppDispatch } from '../../store';

import { Portal } from '../Portal/Portal';
import NotificationItem from './NotificationItem';
import Button from '../Button/Button';
import Heading from '../UI/Heading/Heading';
import Modal from '../Modal/Modal';

import { setCurrentNotificationId } from '../../store/projects/store';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import { ReactComponent as Icon3Dots } from '../../media/icons/more_horizontal.svg';
import { ReactComponent as IconCheckAll } from '../../media/icons/check_all.svg';

import {
  getNotificationList,
  setNotificationViewed,
  setNotificationsViewed,
} from '../../store/projects/thunk';

import { socket } from '../../socket.js';
import { useThemeToggler } from '../../context/ThemeContext';

import './Notification.scss';

interface INotificationProps {
  children?: React.ReactNode;
  isVisible?: boolean;
  setVisible?: (b: boolean) => void;
}

const Notification = forwardRef(
  (
    { isVisible, setVisible = () => {} }: INotificationProps,
    ref: any
  ): JSX.Element | null => {
    const { theme } = useThemeToggler();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [isModalVisible, setIsModalVisible] = useState(isVisible);
    const { inform } = useSelector((state: any) => state.projectData);

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
      const getData = (): void => {
        dispatch(getNotificationList());
      };
      getData();
    }, []);

    useEffect(() => {
      socket.on('informer', (data) => {
        dispatch(getNotificationList());
      });
    }, [dispatch]);

    useEffect(() => {
      if (isMobile) {
        setIsModalVisible(true);
      }
      return () => {
        setIsMenuOpen(false);
      };
    }, [isMobile, isVisible]);

    if (!isVisible) return null;

    return !isMobile ? (
      <Portal>
        <div
          ref={ref}
          className={cx('notification', {
            [`notification--${theme}`]: theme,
          })}
        >
          <Inner
            setVisible={setVisible}
            list={inform?.informerList}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
      </Portal>
    ) : (
      <Modal
        title="Сповіщення"
        isActive={isModalVisible as boolean}
        size="lg"
        handleClose={() => setIsModalVisible(false)}
        leftButton={false}
      >
        <Inner
          ref={ref}
          setVisible={setVisible}
          isMobile={isMobile}
          list={inform?.informerList}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </Modal>
    );
  }
);

const NotificationMenu = forwardRef(
  ({ _, setIsMenuOpen }: any, ref): JSX.Element => {
    const dispatch: AppDispatch = useDispatch();

    return (
      <div ref={ref as any} className="notification__menu">
        <div
          onClick={() => {
            dispatch(setNotificationsViewed());
            setIsMenuOpen(false);
          }}
          className="notification__menu-item"
        >
          <IconCheckAll /> Помітити всі як прочитані
        </div>
        {/* <div className="notification__menu-item">
          <IconMonitor /> Відкрити повідомлення
        </div> */}
      </div>
    );
  }
);

const Inner = forwardRef(
  (
    {
      isMobile,
      isMenuOpen,
      setVisible,
      setIsMenuOpen,
      list,
    }: {
      isMobile?: boolean;
      isMenuOpen: boolean;
      setVisible: (value: boolean) => void;
      setIsMenuOpen: (value: boolean) => void;
      list: any;
    },
    ref: any
  ): JSX.Element => {
    const [filter, setFilter] = useState<0 | 1>(1);
    const menuRef = useRef<HTMLDivElement>(null);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    // useOnClickOutside(menuRef, (e: any) => {
    //   if (menuRef.current && !menuRef.current.contains(e.target)) {
    //     setIsMenuOpen(false);
    //   }
    // });

    const notifList = useMemo(
      () =>
        list.filter((item: any) =>
          filter === 1 ? item : item.status === filter
        ),
      [list, filter]
    );

    const setCurrentId = (id: number): void => {
      dispatch(setCurrentNotificationId(id));
    };

    const setNotificationShowed = (id: number): void => {
      dispatch(setNotificationViewed(id));
    };

    return (
      <div ref={ref} className="notification__inner">
        <div className="notification__top">
          {isMenuOpen && (
            <NotificationMenu setIsMenuOpen={setIsMenuOpen} ref={menuRef} />
          )}
          {!isMobile && (
            <Heading className="notification__title" as="h4" size="sm">
              Сповіщення
              <button
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
                className={cx('notification__menu-button', {
                  'notification__menu-button--active': isMenuOpen,
                })}
              >
                <Icon3Dots />
              </button>
            </Heading>
          )}
          <div className="notification__filters">
            <Button
              onClick={() => setFilter(1)}
              className="notification__filter"
              view={filter === 1 ? 'default' : 'base'}
            >
              Всі
            </Button>
            <Button
              onClick={() => {
                setFilter(0);
              }}
              className="notification__filter"
              view={filter === 0 ? 'default' : 'base'}
            >
              Не прочитані
            </Button>
          </div>
        </div>
        <div className="notification__bottom">
          {filter === 0 &&
            !notifList.some((item: any) => item.status === 0) && (
              <div className="notification__empty">
                Не прочитаних повідомлень немає
              </div>
            )}
          {notifList.map((item: any) => (
            <NotificationItem
              notification={item}
              key={item.id}
              user={item.user}
              onViewed={(e: any) => {
                e.stopPropagation();
                if (item.status === 0) {
                  setCurrentId(item.id);
                  setNotificationShowed(item.id);
                  dispatch(getNotificationList());
                }
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                setIsMenuOpen(false);
                navigate(`/task/${item.slug}/`);
                setVisible(false);
                setCurrentId(item.id);
                setNotificationShowed(item.id);
              }}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default Notification;
