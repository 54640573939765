import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import './Button.scss';

interface IButtonProps {
  children: React.ReactNode;
  href?: string;
  type?: 'submit' | 'button';
  view?: string;
  disabled?: boolean;
  as?: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
  variant?: 'fill' | 'outline' | 'text';
  onClick?: () => void;
  onMouseDown?: () => void;
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>(function Button(
  {
    children,
    view,
    onClick,
    className,
    style = {},
    href = '',
    variant = 'outline',
    type = 'button',
    disabled = false,
    as = 'button',
    onMouseDown,
    ...rest
  }: IButtonProps,
  ref
): JSX.Element {
  const classes = cx(
    'btn',
    {
      [`btn--${view}`]: view,
      [`btn--${variant}`]: variant,
    },
    className
  );

  if (href) {
    return (
      <NavLink style={style} to={href} className={classes}>
        {children}
      </NavLink>
    );
  }

  const TagName = as;

  return (
    <TagName
      ref={ref}
      disabled={disabled}
      className={classes}
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      style={style}
      {...rest}
    >
      {children}
    </TagName>
  );
});

export default Button;
