import { createSlice } from '@reduxjs/toolkit';
import {
  createProject,
  fetchProjectList,
  getProjectData,
  getUsersList,
  getUserTasksList,
  deleteProject,
  getProjectArchive,
  getNotificationList,
  setNotificationViewed,
  getStaffTasksArchive,
  setNotificationsViewed,
  getStaffTasks,
  fetchCoSum,
  getSingleTask,
} from './thunk';
import { TProjectData, TUserData } from '../../layout/ProjectList/types';
import { fetchProjectBalance } from '../billing/thunk';

export interface ProjectState {
  title: string;
  projectList: TProjectData[];
  projectInfo: any;
  projectTasksIdsThatShouldBeWithoutChat: number[];
  usersList: TUserData[];
  projectArchive: any;
  projectListLoading: boolean;
  inform: {
    informerList: any;
    nowViewed: number;
    currentId: number;
  };
  projectsCoSum: any;
  projectsCoSumLoading: any;
}

const initialState = {
  title: '',
  projectList: [],
  projectInfo: {},
  projectListLoading: false,
  usersList: [],
  projectTasksIdsThatShouldBeWithoutChat: [],
  projectArchive: [],
  inform: {
    informerList: [],
    nowViewed: 0,
    currentId: 0,
  },
  projectsCoSum: null,
  projectsCoSumLoading: false,
} as ProjectState;

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clearProjectInfo: (state: ProjectState) => {
      state.projectInfo = {};
      state.usersList = [];
    },
    clearProjectCoSum: (state: ProjectState) => {
      state.projectsCoSum = null;
      state.projectsCoSumLoading = false;
    },
    clearProjectList: (state: ProjectState) => {
      state.projectList = [];
    },
    setCurrentNotificationId: (state: ProjectState, action) => {
      state.inform.currentId = action.payload;
    },
    getProjectTasksIdsThatShouldBeWithoutChat: (state: ProjectState) => {
      state.projectTasksIdsThatShouldBeWithoutChat =
        state.projectInfo?.stageList &&
        // state.projectInfo?.stageList?.map((stage: any) =>
        //   stage.taskList.map((task: any) => task.id)
        // );
        // state.projectInfo?.stageList?.reduce(
        //   (acc: any, stage: any) => [
        //     ...acc,
        //     ...stage.taskList.map((task: any) => task.id),
        //   ],
        //   []
        // );
        state.projectInfo?.stageList[0].taskList.map((item: any) => item.id);
    },
    clearAllProjectsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProjectList.fulfilled,
      (state: ProjectState, action) => {
        state.title = action.payload.title;
        state.projectList = action.payload.projectList;
        state.projectListLoading = false;
      }
    );
    builder.addCase(fetchProjectList.rejected, (state: ProjectState) => {
      state.title = '';
      state.projectList = [];
      state.projectListLoading = false;
    });
    builder.addCase(fetchProjectList.pending, (state: ProjectState) => {
      state.projectListLoading = true;
    });
    builder.addCase(createProject.fulfilled, (state: ProjectState) => state);
    builder.addCase(createProject.rejected, (state: ProjectState) => state);
    builder.addCase(getProjectData.fulfilled, (state: ProjectState, action) => {
      state.projectInfo = action.payload;
    });
    builder.addCase(getStaffTasks.fulfilled, (state: ProjectState, action) => {
      state.projectInfo = action.payload;
    });
    builder.addCase(getUsersList.fulfilled, (state: ProjectState, action) => {
      state.usersList = action.payload;
    });
    builder.addCase(getUsersList.rejected, (state: ProjectState) => {
      state.usersList = [];
    });
    builder.addCase(deleteProject.fulfilled, (state: ProjectState) => state);
    builder.addCase(deleteProject.rejected, (state: ProjectState) => state);
    builder.addCase(
      getUserTasksList.fulfilled,
      (state: ProjectState, action) => {
        state.projectInfo = action.payload;
      }
    );
    builder.addCase(getUserTasksList.rejected, (state: ProjectState) => {
      state.projectInfo = [];
    });
    builder.addCase(
      getProjectArchive.fulfilled,
      (state: ProjectState, action) => {
        state.projectArchive = action.payload?.data;
      }
    );
    builder.addCase(
      getStaffTasksArchive.fulfilled,
      (state: ProjectState, action) => {
        state.projectArchive = action.payload;
      }
    );
    builder.addCase(
      getNotificationList.fulfilled,
      (state: ProjectState, action) => {
        state.inform.informerList = action.payload?.informerList;
        state.inform.nowViewed = action.payload?.nowViewed;
      }
    );
    builder.addCase(setNotificationsViewed.fulfilled, (state: ProjectState) => {
      state.inform.nowViewed = 0;
    });
    builder.addCase(setNotificationViewed.fulfilled, (state: ProjectState) => {
      const current = state.inform?.informerList?.find(
        (item: any) => item.id === state.inform.currentId
      );
      if (state.inform.informerList[current]) {
        state.inform.informerList[current].status = 1;
      }
      if (state.inform.nowViewed > 0) {
        state.inform.nowViewed -= 1;
      }
    });
    builder.addCase(
      fetchProjectBalance.fulfilled,
      (state: ProjectState, action) => {
        state.projectInfo.balance = action.payload.balance;
      }
    );
    builder.addCase(fetchCoSum.fulfilled, (state: ProjectState, action) => {
      state.projectsCoSum = action.payload.data;
      state.projectsCoSumLoading = false;
    });
    builder.addCase(fetchCoSum.rejected, (state: ProjectState) => {
      state.projectsCoSum = null;
      state.projectsCoSumLoading = false;
    });
    builder.addCase(fetchCoSum.pending, (state: ProjectState) => {
      state.projectsCoSumLoading = true;
    });
    builder.addCase(getSingleTask.fulfilled, (state: ProjectState, action) => {
      // make a copy of the state without the task whick contains in payload
      // const prev = state.projectInfo.stageList[0].taskList.filter(
      //   (task: any) => task.id !== action.payload.id
      // );
      // state.projectInfo.stageList[0].taskList = [...prev, action.payload];
      state.projectInfo.stageList = action.payload;
    });
  },
});

const { reducer } = projectSlice;
export const {
  clearProjectInfo,
  clearProjectCoSum,
  clearProjectList,
  setCurrentNotificationId,
  clearAllProjectsData,
  getProjectTasksIdsThatShouldBeWithoutChat,
} = projectSlice.actions;

export default reducer;
